import { imageFilter } from "../../../../../helpers/images";
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { ImageInfo, MetadataUploadInfo } from "../../../../../helpers/interfaces";

type UploadResponse = {
    fileName: string,
    location: string,
    status: string,
}

const CollectibleForm = ({ collectible, setCollectible, signature, wallet, collectibleInfo }: {
    collectible: MetadataUploadInfo | undefined,
    setCollectible: Dispatch<SetStateAction<MetadataUploadInfo | undefined>>,
    signature: string,
    wallet: WalletContextState,
    collectibleInfo: any | undefined,
}) => {
    const [isCoverUpload, setIsCoverUpload] = useState<boolean>(false);
    const hiddenCoverInput = useRef<HTMLInputElement>(null);

    const updateUploadStatus = (type: string, status: boolean) => {
        switch (type) {
            case "cover":
                setIsCoverUpload(status);
                break;
            default:
                console.log("Invalid type");
        }
    }

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            const target = e.target as HTMLInputElement
            const filesUploaded = target.files && Array.from(target.files);

            if (filesUploaded === null || filesUploaded.length === 0) {
                return;
            }

            updateUploadStatus(type, true);
            let formData = new FormData()
            let publicKey = wallet.publicKey?.toString() || "";
            formData.append('publicKey', publicKey);
            formData.append('signature', signature);

            for (let file of filesUploaded) {
                formData.append('files', file)
            }

            let options = {
                method: "POST",
                body: formData,
            };

            let uploadResponses: UploadResponse[] = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/upload`, options)
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        throw res.error
                    } else {
                        return res.data;
                    }
                })

            let uploadedFiles = uploadResponses.filter(res => res.status === "Uploaded.");
            let uploadedImages = uploadedFiles.map(file => ({
                image_url: file.location,
                image_type: type,
            }));

            setCollectible((prevstate: MetadataUploadInfo | undefined) => (
                {
                    ...prevstate,
                    images:
                        !prevstate || !prevstate.images ?
                            [
                                ...uploadedImages,
                            ]
                            :
                            type === "cover" ?
                                [
                                    ...prevstate.images.filter((image: ImageInfo) => image.image_type !== "cover"),
                                    ...uploadedImages,
                                ]
                                :
                                [
                                    ...prevstate.images,
                                    ...uploadedImages,
                                ]
                }
            ))

            updateUploadStatus(type, false);
        } catch (err) {
            console.log(err);
            console.log("Something went wrong. No files were uploaded");
        }
    };

    const handleNameUpdate = (collectibleName: string) => {
        setCollectible((prevstate: MetadataUploadInfo | undefined) => (
            {
                ...prevstate,
                name: collectibleName,
            }
        ))
    };

    const handleDescriptionUpdate = (collectibleDescription: string) => {
        setCollectible((prevstate: MetadataUploadInfo | undefined) => (
            {
                ...prevstate,
                description: collectibleDescription,
            }
        ))
    };

    return (
        <div id="comic-container" className="w-full flex flex-wrap">
            <div className="flex-4 flex flex-col justify-start md:mr-4 mb-4">
                <label htmlFor="comic-name" className="text-white mb-2">Collectible name <span className="text-fuchsia-500">*</span></label>
                <input type="text" name="comic-name" id="comic-name-input" className="rounded px-2"
                    value={collectible?.name || ""}
                    onChange={(e) => handleNameUpdate(e.target.value)}
                    disabled={collectibleInfo !== undefined}
                />
            </div>

            <div className="w-full flex flex-wrap my-2 justify-center md:justify-start">
                <div className="flex flex-col justify-start md:mr-6 mb-4">
                    <label htmlFor="cover-upload" className="text-white mb-2">Collectible cover <span className="text-fuchsia-500">*</span></label>
                    <div className="relative w-56 h-56 border border-dashed border-white flex flex-col justify-center items-center rounded-lg overflow-hidden">
                        {
                            !isCoverUpload ?
                                <>
                                    {
                                        collectibleInfo === undefined &&
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(139 92 246)" className="w-10 h-10 z-10">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                            </svg>
                                            <button
                                                className="text-white bg-violet-500 hover:bg-violet-600 rounded-lg p-4 my-4 mb-8 z-10"
                                                onClick={() => hiddenCoverInput?.current?.click()}
                                            >
                                                Upload
                                            </button>
                                        </>
                                    }
                                    <input ref={hiddenCoverInput} type="file" className="hidden" onChange={(e) => handleFileUpload(e, "cover")} />
                                    {
                                        imageFilter(collectible?.images, "cover")?.length > 0 &&
                                        <div className="absolute w-full h-full p-4">
                                            <div className="w-full h-full overflow-hidden flex items-center">
                                                <img src={imageFilter(collectible?.images, "cover")[0]} alt="cover-page" className="w-full h-auto object-cover opacity-75" />
                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <img src="/loading.gif" alt="spinner" />
                        }
                    </div>
                </div>

                <div className="w-full md:flex-3 flex flex-col justify-start mb-4">
                    <label htmlFor="comic-description" className="text-white mb-2">Collectible description</label>
                    <textarea name="comic-description" id="comic-description-input" className="h-56 p-2 rounded"
                        value={collectible?.description || ""}
                        onChange={(e) => handleDescriptionUpdate(e.target.value)}
                        disabled={collectibleInfo !== undefined}
                    ></textarea>
                </div>
            </div>
        </div>
    )
}

export default CollectibleForm;
