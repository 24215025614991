import {
    CANDY_MACHINE, CANDY_MACHINE_PROGRAM_V2_ID,
} from "./constants";
import { PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";

export const deriveCandyMachineV2ProgramAddress = async (
    candyMachineId: anchor.web3.PublicKey,
): Promise<[PublicKey, number]> => {
    return await PublicKey.findProgramAddress(
        [Buffer.from(CANDY_MACHINE), candyMachineId.toBuffer()],
        CANDY_MACHINE_PROGRAM_V2_ID,
    );
};

export const getTokenDecimal = async (tokenMintString: string): Promise<number> => {
    try {
        let options = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tokenMint: tokenMintString
            }),
        };
        let tokenDecimal: number = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/token/info`, options)
            .then(res => res.json())
            .then(res => {
                if (res?.data && res.status === "success") {
                    return res.data.decimals;
                } else {
                    return 9;
                }
            })

        return tokenDecimal;
    } catch (err) {
        console.log(err);
        return 9;
    }
}