import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SeriesUploadInfo } from "../comics/comic-upload/comic-upload";
import { Comic } from "../../../../../helpers/interfaces";

const SeriesForm = ({ series, setSeries, comicInfo }: { series: SeriesUploadInfo | undefined, setSeries: Dispatch<SetStateAction<SeriesUploadInfo | undefined>>, comicInfo: Comic | undefined }) => {
    const [isExistingSeries, setIsExistingSeries] = useState<boolean>(false);
    const [existingSeries, setExistingSeries] = useState<SeriesUploadInfo[]>([]);

    useEffect(() => {
        if (comicInfo !== undefined && existingSeries?.length > 0) {
            setIsExistingSeries(true);
            let currentSeries: SeriesUploadInfo = existingSeries.filter(series => series.series_id === comicInfo.series.series_id)[0]
            setSeries(currentSeries);
        }
    }, [comicInfo, existingSeries, setSeries])

    useEffect(() => {
        fetchExistingSeries();
    }, [isExistingSeries])

    const fetchExistingSeries = () => {
        try {
            let status: number;
            fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/series`)
                .then(res => {
                    status = res.status;
                    return res.json();
                })
                .then(res => {
                    if (status === 200 && res?.data) {
                        setExistingSeries(res.data);
                    }
                })
                .catch(err => { console.log(err) })
        } catch (error) {
            console.log(error);
        }
    };

    const toggleExistingSeries = (type: string) => {
        if (comicInfo) {
            return;
        }

        setSeries(undefined);

        if (type === "newSeries") {
            setIsExistingSeries(false);
        } else {
            setIsExistingSeries(true);
        }
    };

    const handleSeriesSelection = (seriesName: string) => {
        let seriesSelected: SeriesUploadInfo = existingSeries.filter((series: SeriesUploadInfo) => series.series_name === seriesName)[0];
        if (!seriesSelected) {
            return;
        }
        setSeries(seriesSelected);
    };

    return (
        <div id="series-container" className="w-full h-auto p-6 border border-violet-500">
            {
                existingSeries?.length > 0 ?
                    <>
                        <div className="flex justify-between gap-6 mb-6">
                            {
                                comicInfo === undefined ?
                                    <button className={`w-1/2 h-10 rounded-lg hover:bg-violet-600 hover:text-white pointer ${!isExistingSeries ? `bg-violet-500 text-white` : "text-violet-500 border border-violet-500"}`}
                                        onClick={() => toggleExistingSeries("newSeries")}
                                    >
                                        New series
                                    </button>
                                    :
                                    <button className={`w-1/2 h-10 rounded-lg text-gray-500 border border-gray-500 cursor-not-allowed`}
                                    >
                                        New series
                                    </button>
                            }
                            <button className={`w-1/2 h-10 rounded-lg hover:bg-violet-600 hover:text-white pointer ${isExistingSeries ? `bg-violet-500 text-white` : "text-violet-500 border border-violet-500"}`}
                                onClick={() => toggleExistingSeries("existingService")}
                            >
                                Existing series
                            </button>
                        </div>
                        <div>
                            {
                                !isExistingSeries ?
                                    <div className="flex flex-col gap-4 min-h-40">
                                        <div className="flex flex-col">
                                            <label htmlFor="series-name" className="text-white mb-2">Series name <span className="text-fuchsia-500">*</span></label>
                                            <input
                                                name="series-name"
                                                type="text"
                                                className="p-2 rounded"
                                                value={series?.series_name || ""}
                                                onChange={(e) => { setSeries((prevState: any) => ({ ...prevState, series_name: e.target.value })) }}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="series-description" className="text-white mb-2">Series description</label>
                                            <textarea
                                                name="series-description"
                                                className="p-2 rounded"
                                                value={series?.series_description || ""}
                                                onChange={(e) => { setSeries((prevState: any) => ({ ...prevState, series_description: e.target.value })) }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    comicInfo === undefined ?
                                        <div className="w-full min-h-40 text-black">
                                            <select name="series-selection" id="series-selection" className="w-full p-2"
                                                value={series?.series_name ? series?.series_name : "default"}
                                                onChange={(e) => { handleSeriesSelection(e.target.value) }}
                                            >
                                                <option value="default" disabled hidden>Select an existing series here...</option>
                                                {
                                                    existingSeries.map((series) => (
                                                        <option key={series.series_id} value={series.series_name}>{series.series_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        :
                                        <div className="w-full min-h-40 text-black">
                                            <input name="series-selection" id="series-selection" className="w-full p-2 rounded" value={series?.series_name || ""} disabled />
                                        </div>
                            }
                        </div>
                    </>
                    :
                    <div className="w-full h-40 flex justify-center items-center">
                        <img className="w-60 h-auto" src="/loading.gif" alt="spinner" />
                    </div>

            }
        </div>
    )
};

export default SeriesForm;