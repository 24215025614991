import { getAtaForMint } from './utils';
import {
    awaitTransactionSignatureConfirmation,
    CandyMachineAccount,
    createAccountsForMint,
    getCollectionPDA,
    mintOneToken,
    SetupState,
} from './candy-machine';
import {
    Commitment,
    Connection,
    Transaction,
} from '@solana/web3.js';
import { DEFAULT_TIMEOUT } from './connection';
import { getHoverColor } from '../../../helpers/colors';
import { useAlert } from 'react-alert'
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as anchor from '@project-serum/anchor';

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
    rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const MintButton = ({ candyMachine, color, isPromo, wallet, getComicCollection }: { candyMachine: CandyMachineAccount | undefined, color?: string | undefined, isPromo: boolean, wallet: any, getComicCollection?: any }) => {
    const [itemsRemaining, setItemsRemaining] = useState<number>();
    const [needTxnSplit, setNeedTxnSplit] = useState(true);
    const [setupTxn, setSetupTxn] = useState<SetupState>();

    // eslint-disable-next-line
    const [discountPrice, setDiscountPrice] = useState<anchor.BN>();

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [isSoldOut, setIsSoldOut] = useState<boolean>(false);


    const alert = useAlert()

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const refreshCandyMachineState = useCallback(
        async (commitment: Commitment = 'confirmed') => {
            try {
                if (!anchorWallet) {
                    return;
                }

                if (candyMachine) {
                    const connection = new Connection(rpcHost, commitment);
                    try {
                        const cndy = candyMachine;
                        let isPresale = false;
                        let isActive = false;

                        if (cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000) {
                            isActive = true;
                        }

                        // duplication of state to make sure we have the right values!
                        let isWLUser = false;
                        let userPrice = cndy.state.price;

                        // whitelist mint?
                        if (cndy?.state.whitelistMintSettings) {
                            // is it a presale mint?
                            if (
                                cndy.state.whitelistMintSettings.presale &&
                                (!cndy.state.goLiveDate ||
                                    cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
                            ) {
                                isPresale = true;
                            }
                            // is there a discount?
                            if (cndy.state.whitelistMintSettings.discountPrice) {
                                userPrice = cndy.state.whitelistMintSettings.discountPrice;
                                setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
                            } else {
                                setDiscountPrice(undefined);
                                // when presale=false and discountPrice=null, mint is restricted
                                // to whitelist users only
                                if (!cndy.state.whitelistMintSettings.presale) {
                                    cndy.state.isWhitelistOnly = true;
                                }
                            }
                            // retrieves the whitelist token
                            const mint = new anchor.web3.PublicKey(
                                cndy.state.whitelistMintSettings.mint,
                            );
                            const token = (
                                await getAtaForMint(mint, anchorWallet.publicKey)
                            )[0];

                            try {
                                const balance = await connection.getTokenAccountBalance(token);

                                isWLUser = parseInt(balance.value.amount) > 0;
                                // only whitelist the user if the balance > 0
                                if (cndy.state.isWhitelistOnly) {
                                    isActive = isWLUser;
                                }
                            } catch (e) {
                                // no whitelist user, no mint
                                if (cndy.state.isWhitelistOnly) {
                                    isActive = false;
                                }
                                console.log('There was a problem fetching whitelist token balance');
                                console.log(e);
                            }
                        }
                        userPrice = isWLUser ? userPrice : cndy.state.price;

                        if (cndy?.state.tokenMint) {
                            // retrieves the SPL token
                            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
                            const token = (
                                await getAtaForMint(mint, anchorWallet.publicKey)
                            )[0];
                            try {
                                const balance = await connection.getTokenAccountBalance(token);

                                const valid = new anchor.BN(balance.value.amount).gte(userPrice);

                                // only allow user to mint if token balance >  the user if the balance > 0
                                isActive = isActive && valid;
                            } catch (e) {
                                isActive = false;
                                // no whitelist user, no mint
                                console.log(e);
                            }
                        } else {
                            const balance = new anchor.BN(
                                await connection.getBalance(anchorWallet.publicKey),
                            );
                            const valid = balance.gte(userPrice);
                            isActive = isActive && valid;
                        }

                        // datetime to stop the mint?
                        if (cndy?.state.endSettings?.endSettingType.date) {
                            if (
                                cndy.state.endSettings.number.toNumber() <
                                new Date().getTime() / 1000
                            ) {
                                setIsClosed(true);
                            }
                        }
                        // amount to stop the mint?
                        if (cndy?.state.endSettings?.endSettingType.amount) {
                            let limit = Math.min(
                                cndy.state.endSettings.number.toNumber(),
                                cndy.state.itemsAvailable,
                            );
                            if (cndy.state.itemsRedeemed < limit) {
                                setItemsRemaining(limit - cndy.state.itemsRedeemed);
                            } else {
                                setItemsRemaining(0);
                                cndy.state.isSoldOut = true;
                            }
                        } else {
                            setItemsRemaining(cndy.state.itemsRemaining);
                        }

                        if (cndy.state.isSoldOut) {
                            setIsSoldOut(true);
                        }

                        const [collectionPDA] = await getCollectionPDA(candyMachine.id);
                        const collectionPDAAccount = await connection.getAccountInfo(
                            collectionPDA,
                        );

                        cndy.state.isPresale = isPresale;
                        cndy.state.isActive = isActive;
                        setIsActive(isActive);

                        const txnEstimate =
                            892 +
                            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
                            (cndy.state.tokenMint ? 66 : 0) +
                            (cndy.state.whitelistMintSettings ? 34 : 0) +
                            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
                            (cndy.state.gatekeeper ? 33 : 0) +
                            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

                        setNeedTxnSplit(txnEstimate > 1230);
                    } catch (e) {
                        if (e instanceof Error) {
                            if (
                                e.message === `Account does not exist ${candyMachine.id}`
                            ) {
                                setIsClosed(true);
                                alert.show(`Couldn't fetch candy machine state from candy machine with address: ${candyMachine.id}, using rpc: ${rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`, {
                                    type: "error",
                                })
                            } else if (
                                e.message.startsWith('failed to get info about account')
                            ) {
                                alert.show(`Couldn't fetch candy machine state with rpc: ${rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`, {
                                    type: "error",
                                })
                            }
                        } else {
                            alert.show(`${e}`, {
                                type: "error",
                            })
                        }
                    }
                } else {
                    setIsActive(false);
                }
            } catch (err) {
                //console.log(err);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [alert, anchorWallet, candyMachine]
    );

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = [],
    ) => {
        try {
            document.getElementById('#identity')?.click();
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                let setupMint: SetupState | undefined;
                if (needTxnSplit && setupTxn === undefined) {
                    alert.show('Please sign account setup transaction', {
                        type: "info",
                    })
                    setupMint = await createAccountsForMint(
                        candyMachine,
                        wallet.publicKey,
                    );
                    let status: any = { err: true };
                    if (setupMint.transaction) {
                        status = await awaitTransactionSignatureConfirmation(
                            setupMint.transaction,
                            DEFAULT_TIMEOUT,
                            connection,
                            true,
                        );
                    }
                    if (status && !status.err) {
                        if (getComicCollection) {
                            getComicCollection();
                        }
                        setSetupTxn(setupMint);
                        alert.show('Setup transaction succeeded! Please sign minting transaction', {
                            type: "info",
                        })
                    } else {
                        alert.show('Mint failed! Please try again!', {
                            type: "error",
                        })
                        return;
                    }
                } else {
                    alert.show('Please sign minting transaction', {
                        type: "info",
                    })
                }

                let mintResult = await mintOneToken(
                    candyMachine,
                    wallet.publicKey,
                    beforeTransactions,
                    afterTransactions,
                    setupMint ?? setupTxn,
                );

                let status: any = { err: true };
                let metadataStatus = null;
                if (mintResult) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintResult.mintTxId,
                        DEFAULT_TIMEOUT,
                        connection,
                        true,
                    );

                    metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            mintResult.metadataKey,
                            'processed',
                        );
                }

                if (status && !status.err && metadataStatus) {
                    // manual update since the refresh might not detect
                    // the change immediately
                    let remaining = itemsRemaining! - 1;
                    setItemsRemaining(remaining);
                    setIsActive((candyMachine.state.isActive = remaining > 0));
                    candyMachine.state.isSoldOut = remaining === 0;
                    setSetupTxn(undefined);
                    alert.show('Congratulations! Mint succeeded!', {
                        type: "success",
                    })
                    refreshCandyMachineState('processed');
                } else if (status && !status.err) {
                    alert.show('Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.', {
                        type: "error",
                    })
                    refreshCandyMachineState();
                } else {
                    alert.show('Mint failed! Please try again!', {
                        type: "error",
                    })
                    refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction timeout! Please try again.';
                } else if (error.message.indexOf('0x137')) {
                    console.log(error);
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    console.log(error);
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }


            alert.show(message, {
                type: "error",
            })
            // updates the candy machine state to reflect the latest
            // information on chain
            refreshCandyMachineState();
        } finally {
            // setIsUserMinting(false);
        }
    };

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        candyMachine,
        refreshCandyMachineState,
    ]);

    const defaultColor = "orange-400";
    const defaultHoverColor = "orange-500";
    const hoverColor = color && getHoverColor(color);

    let buttonStyle = `border-2 border-${color || defaultColor} hover:bg-${hoverColor || defaultHoverColor} text-white w-full py-1 rounded-full uppercase text-base`;
    if (isPromo) {
        buttonStyle = `bg-${color || defaultColor} hover:bg-${hoverColor || defaultHoverColor} text-white w-full py-1 rounded-full uppercase text-base`
    }

    if (isClosed) {
        return (
            <button className={`border-2 border-gray-600 text-gray-600 w-full py-1 rounded-full uppercase text-base cursor-default`}>
                Closed
            </button>
        )
    } else if (isSoldOut) {
        return (
            <button className={`border-2 border-gray-600 text-gray-600 w-full py-1 rounded-full uppercase text-base cursor-default`}>
                Sold out
            </button>
        )
    } else if (!isActive) {
        return (
            <button className="border-2 border-gray-600 text-gray-600 w-full py-1 rounded-full uppercase text-base cursor-default"
            >
                Mint
            </button>
        )
    } else {
        return (
            <button className={buttonStyle}
                onClick={() => onMint()}
            >
                Mint
            </button>
        )
    }
}

export default MintButton;