import { imageFilter } from "../../../../../../../helpers/images";
import { Comic } from "../../../../../../../helpers/interfaces";

const UploadComicBlock = ({ index, comicInfo, setCurrentComicId }: { index: number, comicInfo: Comic, setCurrentComicId: React.Dispatch<React.SetStateAction<number>> }) => {
    let { comic_id, comic_name, comic_episode_number, images } = comicInfo;
    let comicCover: string = imageFilter(images, "cover")[0];

    return (
        <div className="border border-violet-500 h-60 mb-4 flex justify-around items-center text-white p-2">
            <div className="w-40 hidden sm:flex justify-center items-center">
                <p className="w-full text-center">{index + 1}</p>
            </div>
            <img className="w-40 sm:w-1/4 h-full object-cover" src={comicCover} alt={`${comic_name} comic cover`} />
            <div className="w-2/4 flex flex-col sm:flex-row justify-center sm:justify-around items-center p-4 gap-4">
                <p className="text-center">{comic_name} - Episode {comic_episode_number}</p>
                <button
                    className="bg-violet-500 hover:bg-violet-600 rounded py-4 px-8"
                    onClick={() => setCurrentComicId(comic_id)}
                >
                    Edit
                </button>
            </div>
        </div>
    )
}

export default UploadComicBlock;