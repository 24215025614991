import { Collectible } from "../../../../../../helpers/interfaces";
import UploadBlock from "./components/upload-block";

const CollectibleList = ({ collectibleList, setCurrentCollectibleId }: { collectibleList: Collectible[], setCurrentCollectibleId: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <div className="w-full h-full">
            <div className="w-full flex items-center justify-between">
                <h1 className="text-violet-500 text-xl">COLLECTIBLE LIST</h1>
                <button
                    className="bg-violet-500 rounded p-2 px-4 hover:bg-violet-600 text-white"
                    onClick={() => { setCurrentCollectibleId(0) }}
                >
                    New collectible +
                </button>
            </div>
            <div className="w-full min-h-screen-1/2 my-4 border border-violet-500">
                <div className="w-full h-10 bg-violet-500"></div>
                <div className="w-full h-full p-4">
                    {
                        collectibleList?.length > 0 ?
                            collectibleList.map((collectible: Collectible, index: number) => (
                                <UploadBlock key={collectible.collectible_id} index={index} collectibleInfo={collectible} setCurrentCollectibleId={setCurrentCollectibleId} />
                            ))
                            :
                            <div className="w-full h-128 flex justify-center items-center">
                                <img className="w-60 h-auto" src="/loading.gif" alt="spinner" />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CollectibleList;