import { imageFilter } from "../../../../../helpers/images";
import { SeriesUploadInfo } from "../comics/comic-upload/comic-upload";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Comic, MetadataUploadInfo } from "../../../../../helpers/interfaces";

type UploadResponse = {
    fileName: string,
    location: string,
    status: string,
}

const ComicForm = ({ comic, setComic, series, signature, wallet, comicInfo }: {
    comic: MetadataUploadInfo | undefined,
    setComic: Dispatch<SetStateAction<MetadataUploadInfo | undefined>>,
    series: SeriesUploadInfo | undefined,
    signature: string,
    wallet: WalletContextState,
    comicInfo: Comic | undefined,
}) => {
    const [isCoverUpload, setIsCoverUpload] = useState<boolean>(false);
    const [isPreviewUpload, setIsPreviewUpload] = useState<boolean>(false);
    const [isPageUpload, setIsPageUpload] = useState<boolean>(false);

    const hiddenCoverInput = useRef<HTMLInputElement>(null);
    const hiddenPreviewInput = useRef<HTMLInputElement>(null);
    const hiddenPageInput = useRef<HTMLInputElement>(null);

    const updateUploadStatus = (type: string, status: boolean) => {
        switch (type) {
            case "cover":
                setIsCoverUpload(status);
                break;
            case "preview":
                setIsPreviewUpload(status);
                break;
            case "page":
                setIsPageUpload(status);
                break;
            default:
                console.log("Invalid type");
        }
    }

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            const target = e.target as HTMLInputElement
            const filesUploaded = target.files && Array.from(target.files);

            if (filesUploaded === null || filesUploaded.length === 0) {
                return;
            }

            updateUploadStatus(type, true);
            let formData = new FormData()
            let publicKey = wallet.publicKey?.toString() || "";
            formData.append('publicKey', publicKey);
            formData.append('signature', signature);

            for (let file of filesUploaded) {
                formData.append('files', file)
            }

            let options = {
                method: "POST",
                body: formData,
            };

            let uploadResponses: UploadResponse[] = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/upload`, options)
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        throw res.error
                    } else {
                        return res.data;
                    }
                })

            let uploadedFiles = uploadResponses.filter(res => res.status === "Uploaded.");
            let uploadedImages = uploadedFiles.map(file => ({
                image_url: file.location,
                image_type: type,
            }));

            setComic((prevstate) => (
                {
                    ...prevstate,
                    images:
                        !prevstate || !prevstate.images ?
                            [
                                ...uploadedImages,
                            ]
                            :
                            type === "cover" ?
                                [
                                    ...prevstate.images.filter(image => image.image_type !== "cover"),
                                    ...uploadedImages,
                                ]
                                :
                                [
                                    ...prevstate.images,
                                    ...uploadedImages,
                                ]
                }
            ))

            updateUploadStatus(type, false);
        } catch (err) {
            console.log(err);
            console.log("Something went wrong. No files were uploaded");
        }
    };

    const handleImageDeletion = (imageUrl: string, imageType: string) => {
        setComic((prevstate) => (
            {
                ...prevstate,
                images:
                    !prevstate || !prevstate.images ?
                        []
                        :
                        [
                            ...prevstate.images.filter(image => !(image.image_url === imageUrl && image.image_type === imageType)),
                        ]
            }
        ))
    };

    const handleNameUpdate = (comicName: string) => {
        setComic((prevstate) => (
            {
                ...prevstate,
                name: comicName,
            }
        ))
    };

    const handleDescriptionUpdate = (comicDescription: string) => {
        setComic((prevstate) => (
            {
                ...prevstate,
                description: comicDescription,
            }
        ))
    };

    const handleCategoryUpdate = useCallback((category: string) => {
        setComic((prevstate) => (
            {
                ...prevstate,
                categories: [category],
            }
        ))
    }, [setComic]);

    const handleEpisodeUpdate = useCallback((comicEpisodeNumber: string) => {
        try {
            let episodeNumber = parseInt(comicEpisodeNumber);

            setComic((prevstate) => (
                {
                    ...prevstate,
                    episode_number: episodeNumber,
                }
            ))
        } catch (err) {
            console.log(err);
        }
    }, [setComic])

    useEffect(() => {
        let episodeNumber: number = 0;
        if (series?.series_id) {
            episodeNumber = series.episodes.length + 1;
        } else {
            episodeNumber = 1;
        }

        handleEpisodeUpdate(episodeNumber.toString());
    }, [series, handleEpisodeUpdate])

    useEffect(() => {
        handleCategoryUpdate("None");
    }, [handleCategoryUpdate])

    return (
        <div id="comic-container" className="w-full flex flex-wrap">
            <div className="w-full flex flex-wrap my-2">
                <div className="flex-4 flex flex-col justify-start md:mr-4 mb-4">
                    <label htmlFor="comic-name" className="text-white mb-2">Comic name <span className="text-fuchsia-500">*</span></label>
                    <input type="text" name="comic-name" id="comic-name-input" className="rounded px-2"
                        value={comic?.name || ""}
                        onChange={(e) => handleNameUpdate(e.target.value)}
                        disabled={comicInfo !== undefined}
                    />
                </div>
                <div className="w-full md:flex-1 flex flex-col justify-start md:mr-4 mb-4">
                    <label htmlFor="episode-number" className="text-white mb-2">Episode number <span className="text-fuchsia-500">*</span></label>
                    <input type="text" name="episode-number" id="episode-number-input" className="rounded px-2"
                        value={comic?.episode_number || ""}
                        onChange={(e) => handleEpisodeUpdate(e.target.value)}
                        disabled={comicInfo !== undefined}
                    />
                </div>
                <div className="w-full md:flex-1 flex flex-col justify-start mb-4">
                    <label htmlFor="episode-number" className="text-white mb-2">Category</label>
                    <select name="episode-number" id="episode-number-input" className="rounded px-2"
                        value={comic?.categories ? comic?.categories[0] : "None"}
                        onChange={(e) => { handleCategoryUpdate(e.target.value) }}
                    >
                        <option value="None">None</option>
                        <option value="Promo">Promo</option>
                    </select>
                </div>
            </div>

            <div className="w-full flex flex-wrap my-2 justify-center md:justify-start">
                <div className="flex flex-col justify-start md:mr-6 mb-4">
                    <label htmlFor="cover-upload" className="text-white mb-2">Comic cover <span className="text-fuchsia-500">*</span></label>
                    <div className="relative w-56 h-56 border border-dashed border-white flex flex-col justify-center items-center rounded-lg overflow-hidden">
                        {
                            !isCoverUpload ?
                                <>
                                    {
                                        comicInfo === undefined &&
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(139 92 246)" className="w-10 h-10 z-10">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                            </svg>
                                            <button
                                                className="text-white bg-violet-500 hover:bg-violet-600 rounded-lg p-4 my-4 mb-8 z-10"
                                                onClick={() => hiddenCoverInput?.current?.click()}
                                            >
                                                Upload
                                            </button>
                                        </>
                                    }
                                    <input ref={hiddenCoverInput} type="file" className="hidden" onChange={(e) => handleFileUpload(e, "cover")} />
                                    {
                                        imageFilter(comic?.images, "cover")?.length > 0 &&
                                        <div className="absolute w-full h-full p-4">
                                            <div className="w-full h-full overflow-hidden flex items-center">
                                                <img src={imageFilter(comic?.images, "cover")[0]} alt="cover-page" className="w-full h-auto object-cover opacity-75" />
                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <img src="/loading.gif" alt="spinner" />
                        }
                    </div>
                </div>

                <div className="w-full md:flex-3 flex flex-col justify-start mb-4">
                    <label htmlFor="comic-description" className="text-white mb-2">Comic description</label>
                    <textarea name="comic-description" id="comic-description-input" className="h-56 p-2 rounded"
                        value={comic?.description || ""}
                        onChange={(e) => handleDescriptionUpdate(e.target.value)}
                        disabled={comicInfo !== undefined}
                    ></textarea>
                </div>
            </div>

            <div className="w-full flex flex-wrap my-2 justify-center md:justify-start">
                <div className="flex flex-col justify-start md:mr-6 mb-4">
                    <label htmlFor="cover-upload" className="text-white mb-2">Preview pages</label>
                    <div className="w-56 h-56 border border-dashed border-white flex flex-col justify-center items-center rounded-lg">
                        {
                            !isPreviewUpload ?
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(139 92 246)" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                    </svg>
                                    <button
                                        className="text-white bg-violet-500 hover:bg-violet-600 rounded-lg p-4 my-4 mb-8"
                                        onClick={() => hiddenPreviewInput?.current?.click()}
                                    >
                                        Upload
                                    </button>
                                    <input ref={hiddenPreviewInput} type="file" className="hidden" onChange={(e) => handleFileUpload(e, "preview")} multiple />
                                </>
                                :
                                <img src="/loading.gif" alt="spinner" />
                        }
                    </div>
                </div>

                <div className="w-full h-56 md:flex-3 overflow-x-auto relative rounded-lg bg-white md:mt-8 mb-4">
                    <table className="table-auto w-full bg-white text-left">
                        <thead className="bg-violet-300 uppercase sticky top-0">
                            <tr>
                                <th className="w-full py-2 px-4">url</th>
                                <th className="py-2 px-2">action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                imageFilter(comic?.images, "preview").length > 0 &&
                                imageFilter(comic?.images, "preview").map(image_url => {
                                    let imageUrl = image_url.length > 40 ? `${image_url.slice(0, 40)}...${image_url.slice(-40)}` : image_url;
                                    return (
                                        <tr key={image_url} className="even:bg-violet-100">
                                            <td className="py-2 px-4">{imageUrl}</td>
                                            <td className="py-2 px-4 text-center">
                                                <button className={`px-2 rounded-lg ${comicInfo === undefined ? "bg-fuchsia-500 hover:bg-fuchsia-600 pointer" : "bg-gray-500"}`}
                                                    onClick={() => comicInfo === undefined && handleImageDeletion(image_url, "preview")}
                                                >
                                                    X
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                comicInfo === undefined ?
                    <div className="w-full flex flex-wrap my-2 justify-center md:justify-start">
                        <div className="flex flex-col justify-start md:mr-6 mb-4">
                            <label htmlFor="cover-upload" className="text-white mb-2">Pages <span className="text-fuchsia-500">*</span></label>
                            <div className="w-56 h-56 border border-dashed border-white flex flex-col justify-center items-center rounded-lg">
                                {
                                    !isPageUpload ?
                                        <>
                                            {
                                                comicInfo === undefined &&
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(139 92 246)" className="w-10 h-10">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                                    </svg>
                                                    <button
                                                        className="text-white bg-violet-500 hover:bg-violet-600 rounded-lg p-4 my-4 mb-8"
                                                        onClick={() => hiddenPageInput?.current?.click()}
                                                    >
                                                        Upload
                                                    </button>
                                                </>
                                            }
                                            <input
                                                ref={hiddenPageInput}
                                                type="file"
                                                className="hidden"
                                                onChange={(e) => handleFileUpload(e, "page")} multiple
                                            />
                                        </>
                                        :
                                        <img src="/loading.gif" alt="spinner" />
                                }

                            </div>
                        </div>

                        <div className="w-full h-56 md:flex-3 overflow-x-auto relative rounded-lg bg-white md:mt-8 mb-4">
                            <table className="table-auto w-full bg-white text-left">
                                <thead className="bg-violet-300 uppercase sticky top-0">
                                    <tr>
                                        <th className="w-full py-2 px-4">url</th>
                                        <th className="py-2 px-2">action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        imageFilter(comic?.images, "page").length > 0 &&
                                        imageFilter(comic?.images, "page").map((image_url: string) => (
                                            <tr key={image_url} className="even:bg-violet-100">
                                                <td className="py-2 px-4">{`${image_url.slice(0, 40)}...${image_url.slice(-40)}`}</td>
                                                <td className="py-2 px-4 text-center">
                                                    <button className={`px-2 rounded-lg ${comicInfo === undefined ? "bg-fuchsia-500 hover:bg-fuchsia-600 pointer" : "bg-gray-500"}`}
                                                        onClick={() => comicInfo === undefined && handleImageDeletion(image_url, "page")}
                                                    >
                                                        X
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

export default ComicForm;
