
import { Connection } from '@solana/web3.js';
import { Comic } from "../../../helpers/interfaces";
import MintPromo from "../../mints/mint-promo/mint-promo";
import ReactCarousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import "react-multi-carousel/lib/styles.css";
import "./hero-swiper.scss";
// import ArtPromo from '../../mints/art-promo/art-promo';
const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
    },
};

const HeroSwiper = ({ comicCollection, connection, comicList, setComicPreview, wallet, getComicCollection }: { comicCollection: any[] | undefined, connection: Connection, comicList: Comic[], setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>>, wallet: WalletContextState, getComicCollection: any }) => {
    const [promoComics, setPromoComics] = useState<Comic[]>([]);

    useEffect(() => {
        if (comicList && comicList.length > 0) {
            let promoComics = comicList.filter(comic => comic.categories.includes("Promo"));
            setPromoComics(promoComics);
        }
    }, [comicList])

    return (
        <div className="w-screen h-auto">
            <ReactCarousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={false}
                infinite={false}
                autoPlay={true}
                autoPlaySpeed={10000}
                keyBoardControl={true}
                focusOnSelect={false}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={300}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
                {/* <div key="hoverboards_promo" className="w-screen mb-8">
                    <ArtPromo />
                </div> */}
                {
                    promoComics &&
                    promoComics.map(comic => (
                        <div key={comic.comic_id} className="w-screen mb-8">
                            <MintPromo comicCollection={comicCollection} connection={connection} comic={comic} wallet={wallet} setComicPreview={setComicPreview} getComicCollection={getComicCollection} />
                        </div>
                    ))
                }
            </ReactCarousel>
        </div>
    );
}

export default HeroSwiper;