import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import "@solana/wallet-adapter-react-ui/styles.css";

const ConnectButton = ({ color }: { color?: string | undefined }) => {
    const defaultColor = "#fb923c";

    return (
        <WalletDialogButton className="w-full" style={{ backgroundColor: color || defaultColor, borderRadius: "30px", color: "white" }}>
            <p className='text-sm'>
                CONNECT
            </p>
        </WalletDialogButton>
    )
}

export default ConnectButton;