import Pages from "../../components/pages/pages";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { paramToComicName } from "../../helpers/format";

const ComicReader = ({ comicCollection, wallet }: { comicCollection: any[] | undefined, wallet: WalletContextState }) => {
    let { comicId } = useParams();
    const [comicInfo, setComicInfo] = useState<any>({});
    const [isHolder, setIsHolder] = useState<boolean | undefined>(undefined);
    const [showTopButton, setShowTopButton] = useState<boolean>(false);

    useEffect(() => {
        if (comicId && comicCollection !== undefined) {
            let episodeNumber: string = comicId.split("_").slice(-1)[0];
            let comicName: string = comicId.split("_")[0];
            let comics = comicCollection.filter(comic => {
                if (comic.name && comicName) {
                    let nameMatch = comic.name.toLowerCase() === paramToComicName(comicName)
                    let episodeAttribute = comic.attributes.filter((attribute: any) => attribute.trait_type.toLowerCase() === "episode")[0];
                    let episodeMatch = episodeAttribute?.value === episodeNumber;
                    return nameMatch && episodeMatch;
                } else {
                    return false;
                }
            });
            if (comics && comics.length > 0) {
                setComicInfo(comics[0]);
                setIsHolder(true);
            } else {
                setIsHolder(false);
            }
        }
    }, [comicId, comicCollection])

    useEffect(() => {
        if (!wallet || !wallet.connected) {
            setIsHolder(undefined);
            setComicInfo({});
        }
    }, [wallet])

    useEffect(() => {
        setTimeout(() => {
            handleScrollTop();
        }, 500)
        window.addEventListener("scroll", handleScroll, false);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
        }
    }, [])

    const handleScroll = () => {
        let st = document.documentElement.scrollTop;
        let isAboveTheFold = st < window.innerHeight;
        setShowTopButton(!isAboveTheFold);
    }

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="w-full min-h-screen-11/12 flex justify-center items-center text-white text-2xl">
            {
                !wallet || !wallet.connected ?
                    <p>Please connect wallet.</p>
                    :
                    isHolder === undefined ?
                        <p>Loading...</p>
                        :
                        isHolder === false ?
                            <p>Not a holder. Please consider minting this comic.</p>
                            :
                            comicInfo?.content?.length > 0 ?
                                <div className="w-screen flex flex-col items-center mt-10 p-4">
                                    <Pages comicInfo={comicInfo} />
                                    {
                                        showTopButton &&
                                        <button className="
                                    w-16 h-16 fixed bottom-20 right-20 
                                    border-2 border-white text-base text-white font-bold opacity-50 
                                    rounded-xl flex justify-center items-center"
                                            onClick={handleScrollTop}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                                :
                                <p>Comic content not found</p>
            }
        </div>
    )
}

export default ComicReader;