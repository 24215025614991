import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { CandyCreator, CandyUploadInfo } from '../../../../../helpers/interfaces';

const CandyForm = ({ candyMachine, setCandyMachine, isNew }: { candyMachine: CandyUploadInfo | undefined, setCandyMachine: Dispatch<SetStateAction<CandyUploadInfo | undefined>>, isNew: boolean }) => {
    const [creatorAddress, setCreatorAddress] = useState<string>("");
    const [creatorShare, setCreatorShare] = useState<string>("");
    const [whitelistType, setWhitelistType] = useState<string>("none");
    const [importCandy, setImportCandy] = useState<boolean>(false);

    const handleItemsUpdate = (totalNFTs: string): void => {
        try {
            let regex = /^[0-9]+$/gm
            if (!regex.test(totalNFTs) && totalNFTs !== "") {
                throw new Error("Not a number");
            }

            let itemsAvailable: number | undefined = totalNFTs ? parseInt(totalNFTs) : undefined;
            setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                ...prevState,
                itemsAvailable,
            }))
        } catch (err) {
            console.log(err);
        }
    };

    const handleGoLiveUpdate = (date: Date) => {
        setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
            ...prevState,
            goLiveDate: date,
        }))
    };

    const handleEndDateUpdate = (date: Date) => {
        setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
            ...prevState,
            endSettings: {
                type: "Date",
                date: date,
                amount: undefined,
            },
        }))
    };

    const handleEndAmountUpdate = (amount: string) => {
        try {
            let regex = /^[0-9]+$/gm
            if (!regex.test(amount) && amount !== "") {
                throw new Error("Not a number");
            }

            setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                ...prevState,
                endSettings: {
                    type: "Amount",
                    date: undefined,
                    amount: amount || undefined,
                },
            }))
        } catch (err) {
            console.log(err);
        }
    }

    const handlePriceUpdate = (price: string) => {
        try {
            let regex = /^[0-9]+([\\.])?([0-9]+)?$/gm
            if (!regex.test(price) && price !== "") {
                throw new Error("Not a number");
            }

            setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                ...prevState,
                price,
            }))
        } catch (err) {
            console.log(err);
        }
    }

    const handleDiscountUpdate = (discount: string) => {
        try {
            let regex = /^[0-9]+([\\.])?([0-9]+)?$/gm
            if (!regex.test(discount) && discount !== "") {
                throw new Error("Not a number");
            }

            setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                ...prevState,
                whitelistSettings: {
                    burn: prevState?.whitelistSettings?.burn,
                    mint: prevState?.whitelistSettings?.mint,
                    presale: prevState?.whitelistSettings?.presale,
                    discountedPrice: discount,
                },
            }))
        } catch (err) {
            console.log(err);
        }
    }

    const handleSellerFeeUpdate = (sellerFee: string) => {
        try {
            let regex = /^[0-9]+$/gm
            if (!regex.test(sellerFee) && sellerFee !== "") {
                throw new Error("Not a number");
            }
            let sellerFeeBasisPoint: number | undefined = sellerFee ? parseInt(sellerFee) : undefined;

            setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                ...prevState,
                sellerFeeBasisPoint
            }))
        } catch (err) {
            console.log(err);
        }
    }

    const handleProportionUpdate = (share: string) => {
        try {
            if (share === "") {
                return setCreatorShare("");
            }

            let regex = /^[0-9]+$/gm
            if (!regex.test(share)) {
                throw new Error("Not a number");
            }

            let shareNumber: number = parseInt(share);
            if (shareNumber < 0) {
                shareNumber = 0;
            }

            let totalProportion = candyMachine?.creators ? candyMachine.creators.reduce((acc, creator) => (acc + creator.share), 0) : 0;

            if (totalProportion + shareNumber > 100) {
                throw new Error("Over 100 total");
            } else {
                setCreatorShare(shareNumber.toString());
            }

        } catch (err) {
            console.log(err);
        }
    };

    const handleAddCreator = () => {
        try {
            if (creatorAddress && creatorShare) {
                let proportionNumber = parseInt(creatorShare);
                setCandyMachine((prevState: CandyUploadInfo | undefined) => (
                    {
                        ...prevState,
                        creators: prevState?.creators ?
                            [...prevState?.creators, { address: creatorAddress, share: proportionNumber }]
                            :
                            [{ address: creatorAddress, share: proportionNumber }],
                    }
                ));
                setCreatorAddress("");
                setCreatorShare("");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleRemoveCreator = (address: string, share: number) => {
        setCandyMachine((prevState: CandyUploadInfo | undefined) => (
            {
                ...prevState,
                creators: prevState?.creators ?
                    [...prevState.creators.filter((creator: CandyCreator) => !(creator.address === address && creator.share === share))]
                    :
                    [],
            }
        ));
    };

    const handleTokenType = (type: string) => {
        setCandyMachine((prevState: CandyUploadInfo | undefined) => (
            {
                ...prevState,
                isSplToken: type !== "SOL",
                splToken: type === "SOL" ? undefined : prevState?.splToken,
            }
        ));
    };

    const handleEndSettingType = (type: string) => {
        setCandyMachine((prevState: CandyUploadInfo | undefined) => (
            {
                ...prevState,
                endSettings: type !== "None" ?
                    {
                        type: type === "Date" ? "Date" : "Amount",
                        date: type === "Date" ? (new Date()) : undefined,
                        amount: type === "Amount" ? "0" : undefined,
                    }
                    : undefined
            }
        ));
    };

    useEffect(() => {
        let isPresale: boolean = whitelistType.includes("presale");
        let isDiscount: boolean = whitelistType.includes("discount");
        if (whitelistType === "none") {
            setCandyMachine((prevState) => ({
                ...prevState,
                whitelistSettings: undefined,
            }))
        } else {
            setCandyMachine((prevState) => ({
                ...prevState,
                whitelistSettings: {
                    burn: prevState?.whitelistSettings?.burn !== undefined ? prevState?.whitelistSettings?.burn : true,
                    mint: prevState?.whitelistSettings?.mint,
                    presale: isPresale,
                    discountedPrice: isDiscount ? prevState?.whitelistSettings?.discountedPrice : undefined,
                },
            }))
        }
    }, [whitelistType, setCandyMachine])

    useEffect(() => {
        if (candyMachine?.whitelistSettings && !importCandy) {
            setImportCandy(true);
            const { presale, discountedPrice } = candyMachine.whitelistSettings;
            if (presale && discountedPrice) {
                setWhitelistType("presalediscount");
            } else if (!presale && !discountedPrice) {
                setWhitelistType("whitelistonly");
            } else if (presale) {
                setWhitelistType("presale");
            } else if (discountedPrice) {
                setWhitelistType("discount");
            } else {
                setWhitelistType("none");
            }
        }
    }, [candyMachine?.whitelistSettings, importCandy])

    const handleWhitelistTypeUpdate = (type: string) => {
        if (type.includes("discount")) {
            handleDiscountUpdate("0");
        }
        setWhitelistType(type);
    };

    return (
        <div className="candy-machine-container border border-violet-500 h-auto flex flex-col items-start justify-center p-4">
            <h1 className="w-full text-center text-xl text-violet-500 uppercase mt-4 mb-8">Candy machine settings</h1>

            <div className='w-full flex flex-row flex-wrap items-center'>
                <div className="flex-grow flex flex-col justify-start mb-4 md:mr-4">
                    <label htmlFor="items-available" className="text-white mb-2">Total NFTs <span className="text-fuchsia-500">*</span></label>
                    <input type="text" name="items-available" id="items-available-input" className="md:w-32 rounded px-2"
                        value={candyMachine?.itemsAvailable || ""}
                        onChange={(e) => handleItemsUpdate(e.target.value)}
                    />
                </div>
            </div>

            <div className='w-full flex flex-row flex-wrap items-center mb-4'>
                <div className='flex-1 mb-4'>
                    <p className='text-white mb-2'>Go live date <span className="text-fuchsia-500">*</span></p>
                    <DateTimePicker onChange={handleGoLiveUpdate} value={candyMachine?.goLiveDate || new Date()} className="bg-white" />
                </div>


                <div className='flex-1 w-full md:w-auto flex-grow flex flex-col justify-center mb-4'>
                    <label className='text-white mb-2' htmlFor="end-settings">End settings</label>
                    <div className='flex flex-row items-center'>
                        <select name="end-settings" id="end-settings" className='h-7 rounded mr-2'
                            value={candyMachine?.endSettings?.type || "None"}
                            onChange={(e) => { handleEndSettingType(e.target.value) }}
                        >
                            <option value="None">None</option>
                            <option value="Date">By date</option>
                            <option value="Amount">By amount</option>
                        </select>
                        <div className='w-full h-7'>
                            {
                                candyMachine?.endSettings?.type === "Date" &&
                                <DateTimePicker onChange={handleEndDateUpdate} value={candyMachine.endSettings.date} className="bg-white" />
                            }
                            {
                                candyMachine?.endSettings?.type === "Amount" &&
                                <input type="text" name="end-settings-number" id="end-settings-number-input" className="w-24 h-7 rounded px-2"
                                    value={candyMachine?.endSettings?.amount || ""}
                                    onChange={(e) => handleEndAmountUpdate(e.target.value)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-wrap border border-violet-500 p-4 mb-4'>
                <div className='w-full flex flex-row flex-wrap items-center mb-4'>
                    <div className="md:flex-1 flex flex-col justify-start md:mr-4 mb-4">
                        <label htmlFor="price" className="text-white mb-2">Price <span className="text-fuchsia-500">*</span></label>
                        <div className='flex flex-row'>
                            <input type="text" name="collection-name" id="price-input" className="w-24 md:w-auto rounded px-2 mr-2"
                                value={candyMachine?.price !== undefined ? candyMachine.price : ""}
                                onChange={(e) => handlePriceUpdate(e.target.value)}
                            />
                            <select name="price" id="price-select" className='w-22 rounded'
                                value={candyMachine?.isSplToken ? "SPL-TOKEN" : "SOL"}
                                onChange={(e) => handleTokenType(e.target.value)}
                            >
                                <option value="SOL">SOL</option>
                                <option value="SPL-TOKEN">SPL-TOKEN</option>
                            </select>
                        </div>
                    </div>

                    <div className="relative md:flex-1 flex flex-col justify-start md:mr-4 mb-4">
                        <label htmlFor="seller-fee" className="text-white mb-2">Seller fee basis point</label>
                        <input type="text" name="seller-fee" id="seller-fee-input" className="w-24 relative rounded px-2 mr-2"
                            value={candyMachine?.sellerFeeBasisPoint || ""}
                            onChange={(e) => handleSellerFeeUpdate(e.target.value)}
                            disabled={!isNew}
                        />
                        <p className='text-black absolute bottom-0 left-20'>%</p>
                    </div>
                </div>

                <div className='w-full flex flex-row flex-wrap items-center mb-4'>
                    <div className="w-full md:flex-1 flex flex-col justify-start md:mr-4 mb-4">
                        <label htmlFor="spl-token" className="text-white mb-2">SPL Token</label>
                        {
                            !candyMachine?.isSplToken ?
                                <input type="text" name="seller-fee" id="spl-token-input" className="rounded px-2 mr-2"
                                    value={""}
                                    disabled
                                />
                                :
                                <input type="text" name="seller-fee" id="spl-token-input" className="rounded px-2 mr-2"
                                    value={candyMachine?.splToken || ""}
                                    onChange={(e) => setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                                        ...prevState,
                                        splToken: e.target.value,
                                    }))}
                                />
                        }
                    </div>

                    <div className="flex-1 flex flex-col justify-start md:mr-4 mb-4">
                        <label htmlFor="treasury-wallet" className="text-white mb-2">Treasury wallet</label>
                        <input type="text" name="treasury-wallet" id="treasury-wallet-input" className="rounded px-2 mr-2"
                            value={candyMachine?.treasuryWallet || ""}
                            onChange={(e) => setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                                ...prevState,
                                treasuryWallet: e.target.value,
                            }))}
                        />
                    </div>
                </div>

            </div>
            <div className='w-full flex flex-wrap border border-violet-500 p-4'>
                <div className='flex-1 flex flex-col justify-center'>
                    <label className='text-white mb-2' htmlFor="whitelist-settings">Whitelist settings</label>
                    <div className='w-1/2 flex flex-row items-center mb-2'>
                        <select name="whitelist-settings" id="whitelist-settings" className='w-full h-7 rounded mr-2'
                            value={whitelistType}
                            onChange={(e) => { handleWhitelistTypeUpdate(e.target.value) }}
                        >
                            <option value="none">None</option>
                            <option value="presale">Presale</option>
                            <option value="presalediscount">Presale with discount</option>
                            <option value="discount">Public sale with discount</option>
                            <option value="whitelistonly">Whitelist only</option>
                        </select>
                    </div>
                    <div className='w-full flex flex-row items-center'>
                        {
                            whitelistType !== "none" &&
                            <>
                                <div className='flex-1 flex flex-col justify-start mr-4 mb-4'>
                                    <label htmlFor='should-burn' className="text-white mb-2">Should burn token on use?</label>
                                    <select name="should-burn" id="should-burn" className='w-full h-7 rounded mr-2'
                                        value={candyMachine?.whitelistSettings?.burn === false ? "false" : "true"}
                                        onChange={(e) => setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                                            ...prevState,
                                            whitelistSettings: {
                                                burn: e.target.value === "true",
                                                mint: prevState?.whitelistSettings?.mint,
                                                presale: prevState?.whitelistSettings?.presale,
                                                discountedPrice: prevState?.whitelistSettings?.discountedPrice,
                                            },
                                        }))}
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                                <div className="flex-1 flex flex-col justify-start mr-4 mb-4">
                                    <label htmlFor="whitelist-mint" className="text-white mb-2">Whitelist token mint address <span className="text-fuchsia-500">*</span></label>
                                    <input type="text" name="whitelist-mint" id="whitelist-mint-input" className="w-full h-7 rounded px-2"
                                        value={candyMachine?.whitelistSettings?.mint || ""}
                                        onChange={(e) => setCandyMachine((prevState: CandyUploadInfo | undefined) => ({
                                            ...prevState,
                                            whitelistSettings: {
                                                burn: prevState?.whitelistSettings?.burn !== undefined ? prevState?.whitelistSettings?.burn : true,
                                                mint: e.target.value,
                                                presale: prevState?.whitelistSettings?.presale,
                                                discountedPrice: prevState?.whitelistSettings?.discountedPrice,
                                            },
                                        }))}
                                    />
                                </div>
                            </>
                        }
                        {
                            whitelistType?.includes("discount") ?
                                <div className="relative flex-1 flex flex-col justify-start mb-4">
                                    <label htmlFor="whitelist-discount" className="text-white mb-2">Discounted price</label>
                                    <input type="text" name="whitelist-discount" id="whitelist-discount-input" className="w-full h-7 rounded px-2"
                                        value={candyMachine?.whitelistSettings?.discountedPrice !== undefined ? candyMachine?.whitelistSettings?.discountedPrice : ""}
                                        onChange={(e) => handleDiscountUpdate(e.target.value)}
                                    />
                                    <span className='absolute bottom-1 right-2'>{candyMachine?.isSplToken ? "SPL-TOKEN" : "SOL"}</span>
                                </div>
                                :
                                <div className='flex-1'></div>
                        }
                    </div>
                </div>
            </div>
            {
                isNew ?
                    <div id="creator-container" className="w-full flex flex-col justify-start items-start mt-4">
                        <label htmlFor="creators" className="text-white mb-2">Creator wallets (royalty shares) <span className="text-fuchsia-500">*</span></label>
                        <div className="w-full overflow-x-auto relative rounded-lg h-60 bg-white mb-4">
                            <table className="table-auto w-full bg-white text-left">
                                <thead className="bg-violet-300 uppercase sticky top-0">
                                    <tr>
                                        <th className="w-1/2 py-2 px-4">wallet address</th>
                                        <th className="w-1/2 py-2 px-4">proportion</th>
                                        <th className="py-2 px-2">action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="even:bg-violet-100">
                                        <td className="py-2 px-4">
                                            <input
                                                type="text"
                                                className="w-full border-violet-200 border-2 rounded-lg px-2"
                                                value={creatorAddress || ""}
                                                onChange={(e) => setCreatorAddress(e.target.value)}
                                            />
                                        </td>
                                        <td className="py-2 px-4">
                                            <input
                                                type="text"
                                                className="w-full border-violet-200 border-2 rounded-lg px-2"
                                                value={creatorShare || ""}
                                                onChange={(e) => handleProportionUpdate(e.target.value)}
                                            />
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <button className={` px-2 py-1 rounded-lg ${isNew ? "bg-violet-500 hover:bg-violet-600 pointer" : "bg-gray-500"}`}
                                                onClick={() => isNew && handleAddCreator()}
                                            >
                                                +
                                            </button>
                                        </td>
                                    </tr>
                                    {
                                        candyMachine?.creators &&
                                        candyMachine?.creators.map(creator => (
                                            <tr key={creator.address} className="even:bg-violet-100">
                                                <td className="py-2 px-4">
                                                    {creator.address}
                                                </td>
                                                <td className="py-2 px-4">
                                                    {creator.share}
                                                </td>
                                                <td className="py-2 px-4 text-center">
                                                    <button className="bg-fuchsia-500 hover:bg-fuchsia-600 px-2 py-1 rounded-lg pointer"
                                                        onClick={() => { handleRemoveCreator(creator.address, creator.share) }}
                                                    >
                                                        X
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

export default CandyForm;