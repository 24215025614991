export const getHoverColor = (color: string): string => {
    if (color.includes("-")) {
        let colorNumber: string = color?.split("-")[1];
        let hoverColorNumber: string = (parseInt(colorNumber) + 100).toString();
        let hoverColor = color?.replace(colorNumber, hoverColorNumber);

        return hoverColor;
    } else {
        return color;
    }
}