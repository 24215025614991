import { Comic } from "../../../helpers/interfaces";
import { imageFilter } from "../../../helpers/images";
import { comicNameToParam } from "../../../helpers/format";
import { Connection } from '@solana/web3.js';
import ConnectButton from "../../buttons/connect-button/connect-button";
import CharacterSwiper from "../../swipers/character-swiper/character-swiper";
import { deriveCandyMachineV2ProgramAddress } from "../../../helpers/metaplex";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine";
import { getTokenDecimal } from "../../../helpers/metaplex";
import { getAtaForMint } from "../../buttons/mint-button/utils";
import { programs } from "@metaplex/js";
import MintButton from "../../buttons/mint-button/mint-button";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

const MintComic = ({ comicCollection, connection, comic, setComicPreview, wallet, getComicCollection }: { comicCollection: any[] | undefined, connection: Connection, comic: Comic, setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>>, wallet: WalletContextState, getComicCollection: any }) => {
    const [candyMachine, setCandyMachine] = useState<any>();
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>();
    const [splTokenName, setSplTokenName] = useState<string>("");
    const [comicPrice, setComicPrice] = useState<string>("--");

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet) {
                let cndy;
                try {
                    const candyMachineId = new anchor.web3.PublicKey(
                        comic.comic_cm_id,
                    );

                    cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                    setCandyMachine(cndy);

                    let [candyMachineAddr] = await deriveCandyMachineV2ProgramAddress(candyMachineId);
                    setCandyMachineAddress(candyMachineAddr.toString());
                } catch (err) {
                    console.log("Candy machine not found.")
                    setCandyMachine(undefined);
                    console.log(err);
                }

                if (cndy) {
                    let mintDecimal: number = cndy.state.tokenMint ? await getTokenDecimal(cndy.state.tokenMint.toString()) : 9;
                    let price: string = (cndy.state.price.toNumber() / (10 ** mintDecimal)).toString();
                    let discountPrice: string = "";
                    let isWLUser: boolean = false;

                    if (cndy.state.whitelistMintSettings !== null) {
                        const WLToken = (await getAtaForMint(cndy.state.whitelistMintSettings.mint, anchorWallet.publicKey))[0];
                        const balance = await connection.getTokenAccountBalance(WLToken);
                        isWLUser = parseInt(balance.value.amount) > 0;
                        discountPrice = cndy.state.whitelistMintSettings?.discountPrice ? (cndy.state.whitelistMintSettings.discountPrice.toNumber() / (10 ** mintDecimal)).toString() : "";
                    }
                    if (cndy.state.tokenMint !== null) {
                        try {
                            if (cndy.state.tokenMint.toString() === "CtEYJ44W7PctR1Bc7tHJxfEX4vvzc8aAxZYzGwGWscT9") {
                                setSplTokenName("EVO");
                            } else {
                                let mintInfo = await programs.metadata.Metadata.findByMint(connection, cndy.state.tokenMint);
                                let tokenName: string = mintInfo?.data?.data?.name || "";
                                setSplTokenName(tokenName);
                            }
                        } catch (err) {
                            console.log("Token info not found.")
                            setSplTokenName("Unkown");
                        }
                    }

                    let currentPrice: string = discountPrice && isWLUser ? discountPrice : price;
                    setComicPrice(currentPrice);
                }

            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, comic, connection])

    return (
        <div className="w-76 min-h-1/2 mb-6 flex flex-col justify-center items-center border-2 border-orange-300 p-4 rounded">
            <div className="w-full h-full mb-4 flex flex-col justify-between items-center">
                <img className="w-full h-96 object-cover mb-4 border-2 border-gray-500 p-2 rounded" src={imageFilter(comic?.images, "cover")[0]} alt={`${comic.comic_name} cover`} />
                <h2 className="text-white font-bold text-lg text-center uppercase">{comic.series.series_name}</h2>
                <h3>{comic.comic_name}</h3>
                <p>{`Episode ${comic.comic_episode_number}`}</p>
                <div className="flex flex-row flex-wrap whitespace-nowrap mb-4 justify-center">
                    <p>{comic.comic_page_count && `${comic.comic_page_count} pages`}</p>
                    {
                        candyMachine && candyMachine?.state &&
                        <>
                            <p className="mx-2">|</p>
                            <p>
                                {`Editions: ${candyMachine.state.itemsAvailable}`}
                            </p>
                            <p className="mx-2">|</p>
                            <p>
                                {`${comicPrice} ${splTokenName ? splTokenName : "SOL"}`}
                            </p>
                        </>
                    }
                </div>
                <div className="w-full flex flex-wrap flex-row justify-between px-2">
                    <div className="flex-grow w-24 mx-2 mt-4">
                        {
                            imageFilter(comic?.images, "preview")?.length > 0 ?
                                <button className="
                            border-2 border-orange-400 hover:bg-orange-400 
                            text-white w-full py-1 rounded-full uppercase text-base"
                                    onClick={() => setComicPreview(comic)}
                                >
                                    Preview
                                </button>
                                :
                                <button className="
                            border-2 border-gray-600 text-gray-600 
                            w-full py-1 rounded-full uppercase text-base cursor-default"
                                >
                                    Preview
                                </button>
                        }
                    </div>
                    <div className="flex-grow w-24 mx-2 mt-4">
                        {
                            !wallet || !wallet.connected ?
                                <ConnectButton />
                                :
                                <MintButton candyMachine={candyMachine} isPromo={false} wallet={wallet} getComicCollection={getComicCollection} />
                        }
                    </div>
                    <div className="flex-grow w-24 mx-2 mt-4">
                        {
                            !wallet || !wallet.connected ?
                                <button className="bg-gray-500 text-gray-300 w-full py-1 rounded-full uppercase text-base">
                                    Read
                                </button>
                                :
                                !comicCollection ?
                                    <button className="bg-orange-400 hover:bg-orange-500 text-white w-full h-9 py-1 rounded-full uppercase flex justify-center items-center">
                                        <div className="w-4 h-4 border-l-2 border-white rounded-full animate-spin" />
                                    </button>
                                    :
                                    comicCollection.some(owned_comic => owned_comic.candyMachineAddress === candyMachineAddress) ?
                                        <Link to={{ pathname: `/comic-reader/${comicNameToParam(comic.comic_name)}_${comic.comic_episode_number}` }} >
                                            <button className="bg-orange-400 hover:bg-orange-500 text-white w-full py-1 rounded-full uppercase text-base">
                                                Read
                                            </button>
                                        </Link>
                                        :
                                        <button className="bg-gray-500 text-gray-300 w-full py-1 rounded-full uppercase text-base">
                                            Read
                                        </button>
                        }
                    </div>
                </div>
            </div>
            {
                comic.characters?.length > 0 &&
                <CharacterSwiper comic={comic} connection={connection} characterList={comic.characters} wallet={wallet} />
            }
        </div>
    )
}

export default MintComic