import { Comic } from "../../../helpers/interfaces";
import { Connection } from '@solana/web3.js';
import ConnectButton from "../../buttons/connect-button/connect-button";
import { formatNumber } from "../../buttons/mint-button/utils";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine"
import MintButton from "../../buttons/mint-button/mint-button";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

const MintCharacter = ({ comic, connection, character, index, wallet }: { comic: Comic, connection: Connection, character: any, index: number, wallet: WalletContextState }) => {
    const [candyMachine, setCandyMachine] = useState<any>();

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet])

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet) {
                const candyMachineId = new anchor.web3.PublicKey(
                    comic.comic_cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, comic, connection])

    return (
        <div key={index} className="flex flex-col justify-center items-center mb-8">
            <img className="w-60 h-60 object-cover cursor-pointer mb-4 border-2 border-gray-500 p-2 rounded" src={character.character_image} alt={`swiper ${index}`} />
            <div className="flex flex-col justify-center items-center">
                <h2 className="text-white font-bold text-lg uppercase">{comic.comic_name}</h2>
                <p>{character.character_name}</p>
                <div className="flex flex-row whitespace-nowrap justify-between">
                    {
                        candyMachine && candyMachine?.state &&
                        <>
                            <p>
                                {`Edition: ${candyMachine.state.itemsAvailable}`}
                            </p>
                            <p className="mx-2">|</p>
                            <p>
                                {`${formatNumber.asNumber(candyMachine.state.price)} SOL`}
                            </p>
                        </>
                    }
                </div>
                <div className="w-full flex flex-row justify-center my-4">
                    {
                        !wallet || !wallet.connected ?
                            <ConnectButton />
                            :
                            <MintButton candyMachine={candyMachine} isPromo={false} wallet={wallet} />
                    }
                </div>
            </div>
        </div>
    )
}

export default MintCharacter;