import { Comic } from "../../../helpers/interfaces";
import { comicNameToParam } from "../../../helpers/format";
import { Connection } from '@solana/web3.js';
import ConnectButton from '../../buttons/connect-button/connect-button';
import { deriveCandyMachineV2ProgramAddress } from "../../../helpers/metaplex";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine"
import { formatNumber } from "../../buttons/mint-button/utils";
import MintButton from '../../buttons/mint-button/mint-button';
import { Link } from "react-router-dom";
import { programs } from "@metaplex/js";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';
import { imageFilter } from "../../../helpers/images";

const MintPromo = ({ comicCollection, connection, comic, setComicPreview, wallet, getComicCollection }: { comicCollection: any[] | undefined, connection: Connection, comic: Comic | undefined, setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>>, wallet: WalletContextState, getComicCollection?: any }) => {
    const [candyMachine, setCandyMachine] = useState<any>();
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>();
    const [splTokenName, setSplTokenName] = useState<string>("");

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet])

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet && comic) {
                const candyMachineId = new anchor.web3.PublicKey(
                    comic.comic_cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);
                if (cndy.state.tokenMint !== null) {
                    if (cndy.state.tokenMint.toString() === "CtEYJ44W7PctR1Bc7tHJxfEX4vvzc8aAxZYzGwGWscT9") {
                        setSplTokenName("EVO");
                    } else {
                        let mintInfo = await programs.metadata.Metadata.findByMint(connection, cndy.state.tokenMint);
                        let tokenName: string = mintInfo?.data?.data?.name || "";
                        setSplTokenName(tokenName);
                    }
                }
                
                let [candyMachineAddr] = await deriveCandyMachineV2ProgramAddress(candyMachineId);
                setCandyMachineAddress(candyMachineAddr.toString());
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, comic, connection])

    return (
        <div className="w-full h-auto lg:h-full flex justify-center items-center bg-black px-10 py-5">
            <div className="w-full min-h-screen-1/2 bg-gradient-to-b from-violet-500 via-pink-500 to-orange-300 p-1">
                <div className="relative w-full min-h-screen-1/2 h-full p-2 sm:p-10 px-4 sm:px-20 bg-black text-white flex flex-row flex-wrap justify-around items-center overflow-hidden ">
                    {
                        comic ?
                            <>
                                <div className="hidden lg:flex w-1/3 h-full justify-center items-center p-5">
                                    <img className="w-auto h-96 shadow-purple" src={imageFilter(comic?.images, "cover")[0]} alt="random" />
                                </div>
                                <div className="w-full sm:w-2/3 h-full flex justify-center items-center">
                                    <div className="w-full sm:w-128 h-full text-center lg:text-left">
                                        <h1 className="uppercase font-extrabold text-transparent font-archivo text-4xl bg-clip-text bg-gradient-to-b from-sky-100 to-sky-500 lg:whitespace-nowrap mt-5 mb-10">Drop of the month</h1>

                                        <img className="lg:hidden w-full sm:w-128 h-auto shadow-purple" src={imageFilter(comic?.images, "cover")[0]} alt="random" />

                                        <div className="w-full my-14">
                                            <div className="mb-4">
                                                <h2 className="font-bold text-xl uppercase mb-1">{comic.series.series_name}</h2>
                                                <h3>{comic.comic_name}</h3>
                                            </div>
                                            <div className={`flex flex-row whitespace-nowrap mb-4 justify-between ${!candyMachine?.state && "w-full lg:w-1/2"}`}>
                                                <p>Episode {comic.comic_episode_number}</p>
                                                <p>|</p>
                                                <p>{comic.comic_page_count} pages</p>
                                                {
                                                    candyMachine && candyMachine?.state &&
                                                    <>
                                                        <p>|</p>
                                                        <p>
                                                            {`Edition: ${candyMachine.state.itemsAvailable}`}
                                                        </p>
                                                        <p>|</p>
                                                        {
                                                            candyMachine.state.tokenMint === null ?
                                                                <p>
                                                                    {`${formatNumber.asNumber(candyMachine.state.price)} SOL`}
                                                                </p>
                                                                :
                                                                <p>
                                                                    {`${candyMachine.state.price ? candyMachine.state.price.toNumber() : "N/A"} ${splTokenName}`}
                                                                </p>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            {
                                                comic.comic_description &&
                                                <p>{comic.comic_description}</p>
                                            }
                                        </div>
                                        <div className="w-full flex flex-row flex-wrap justify-center lg:justify-start mb-20 gap-10">
                                            <div className="w-24">
                                                {
                                                    imageFilter(comic?.images, "preview")?.length > 0 ?
                                                        <button className="bg-violet-800 hover:bg-purple-800 text-white w-24 py-1 rounded-full uppercase text-base"
                                                            onClick={() => setComicPreview(comic)}
                                                        >
                                                            Preview
                                                        </button>
                                                        :
                                                        <button className="bg-gray-500 text-gray-400 w-24 py-1 rounded-full uppercase text-base cursor-default">
                                                            Preview
                                                        </button>
                                                }
                                            </div>
                                            <div className="w-24">
                                                {
                                                    !wallet || !wallet.connected ?
                                                        <ConnectButton color={"#7d58db"} />
                                                        :
                                                        <MintButton candyMachine={candyMachine} color={"violet-500"} isPromo={true} wallet={wallet} getComicCollection={getComicCollection} />
                                                }
                                            </div>
                                            <div className="w-24">
                                                {
                                                    !wallet || !wallet.connected ?
                                                        <button className="bg-gray-500 text-gray-300 w-24 py-1 rounded-full uppercase text-base">
                                                            Read
                                                        </button>
                                                        :
                                                        !comicCollection ?
                                                            <button className="bg-violet-400 hover:bg-violet-500 text-white w-24 h-8 py-1 rounded-full uppercase flex justify-center items-center">
                                                                <div className="w-4 h-4 border-l-2 border-white rounded-full animate-spin" />
                                                            </button>
                                                            :
                                                            comicCollection.some(owned_comic => owned_comic.candyMachineAddress === candyMachineAddress) ?
                                                                <Link to={{ pathname: `/comic-reader/${comicNameToParam(comic.comic_name)}_${comic.comic_episode_number}` }} >
                                                                    <button className="bg-violet-400 hover:bg-violet-500 text-white w-24 py-1 rounded-full uppercase text-base">
                                                                        Read
                                                                    </button>
                                                                </Link>
                                                                :
                                                                <button className="bg-gray-500 text-gray-300 w-24 py-1 rounded-full uppercase text-base">
                                                                    Read
                                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="absolute bottom-10 -right-2 w-96 pl-5 flex justify-end">
                                    <div className="z-0 transform -skew-x-25 w-full h-8 flex justify-center items-center bg-gradient-to-r from-violet-500 via-pink-500 to-orange-300 text-black">
                                    </div>
                                </div>
                                <p className="absolute text-black bottom-12 right-16 font-extrabold uppercase text-base">Includes special airdrop</p> */}
                            </>
                            :
                            <div className="w-24 h-24 border-l-2 border-purple-600 rounded-full animate-spin" />
                    }
                </div>
            </div>
        </div >
    )
}

export default MintPromo;