import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import CandyForm from "../../components/candy-form";
import { CandyUploadInfo, Comic, Creator, ImageInfo, MetadataUploadInfo, Series } from "../../../../../../helpers/interfaces";
import { imageFilter } from "../../../../../../helpers/images";
import ComicForm from "../../components/comic-form";
import CreatorForm from "../../components/creator-form";
import { getCandyMachineState } from "../../../../../../components/buttons/mint-button/candy-machine";
import { getTokenDecimal } from "../../../../../../helpers/metaplex";
import { PublicKey } from "@solana/web3.js";
import SeriesForm from "../../components/series-form";
import { useAlert } from 'react-alert'
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

export interface ComicUploadInfo {
    comic_id?: number,
    comic_name?: string,
    comic_episode_number?: number,
    comic_page_count?: number,
    comic_description?: string,
    comic_cm_id?: string,
    images?: ImageInfo[],
    categories?: string[],
}

export interface SeriesUploadInfo extends Series {
    episodes: Comic[],
}

const ComicUpload = ({ wallet, signature, comicInfo, setCurrentComicId }: { wallet: WalletContextState, signature: string, comicInfo: Comic | undefined, setCurrentComicId: Dispatch<SetStateAction<number>> }) => {
    const [creators, setCreators] = useState<Creator[]>([]);
    const [comic, setComic] = useState<MetadataUploadInfo | undefined>(undefined);
    const [series, setSeries] = useState<SeriesUploadInfo | undefined>(undefined);
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [candyMachine, setCandyMachine] = useState<CandyUploadInfo | undefined>({ goLiveDate: new Date() });
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const alert = useAlert()

    useEffect(() => {
        console.log("comicInfo");    
        console.log(comicInfo);    
    }, [comicInfo])

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const getAccountOwner = async (connection: anchor.web3.Connection, tokenAddress: PublicKey): Promise<PublicKey> => {
        let owner = tokenAddress
        let accountInfo = await connection.getParsedAccountInfo(tokenAddress);
        console.log(accountInfo);
        let accountData = accountInfo.value?.data;
        if (!Buffer.isBuffer(accountData)) {
            const ownerAddress = accountData?.parsed?.info?.owner;
            if (ownerAddress) {
                owner = ownerAddress;
            }
        }
        return owner;
    };

    const fetchCandyMachine = useCallback(async (candyMachineIdString: string): Promise<void> => {
        if (anchorWallet) {
            const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
            const connection = new anchor.web3.Connection(
                rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
            );
            const candyMachineId = new PublicKey(candyMachineIdString);
            const cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
            console.log("candyMachine");
            console.log(cndy);

            let price = "";
            let mintDecimal = cndy.state.tokenMint === null ? 9 : await getTokenDecimal(cndy.state.tokenMint.toString());
            if (cndy.state.price) {
                price = (cndy.state.price.toNumber() / (10 ** mintDecimal)).toString();
            }

            let discountedPrice = "";
            if (cndy?.state?.whitelistMintSettings?.discountPrice) {
                discountedPrice = (cndy.state.whitelistMintSettings.discountPrice.toNumber() / (10 ** mintDecimal)).toString();
            }
            let treasuryWallet = cndy.state.treasury.toString();
            if (cndy.state.tokenMint !== null) {
                const accountOwner = await getAccountOwner(connection, cndy.state.treasury);
                treasuryWallet = accountOwner.toString();
            }

            setCandyMachine({
                itemsAvailable: cndy.state.itemsAvailable,
                goLiveDate: new Date(cndy.state.goLiveDate.toNumber() * 1000),
                endSettings: cndy.state.endSettings !== null ?
                    {
                        type: Object.keys(cndy.state.endSettings?.endSettingType)[0] === "date" ? "Date" : "Amount",
                        date: Object.keys(cndy.state.endSettings?.endSettingType)[0] === "date" ? new Date(cndy.state.endSettings.number.toNumber() * 1000) : undefined,
                        amount: Object.keys(cndy.state.endSettings?.endSettingType)[0] !== "date" ? cndy.state.endSettings.number.toString() : undefined,
                    }
                    :
                    undefined,
                price,
                splToken: cndy.state.tokenMint !== null ? cndy.state.tokenMint.toString() : undefined,
                isSplToken: cndy.state.tokenMint !== null,
                sellerFeeBasisPoint: 0,
                treasuryWallet,
                whitelistSettings: cndy.state.whitelistMintSettings !== null ?
                    {
                        burn: Object.keys(cndy?.state?.whitelistMintSettings?.mode)[0] === "burnEveryTime",
                        mint: cndy.state.whitelistMintSettings.mint.toString(),
                        presale: cndy.state.isPresale,
                        discountedPrice,
                    }
                    :
                    undefined,
                creators: [],
            })
        }
    }, [anchorWallet])

    useEffect(() => {
        if (comicInfo) {
            setComic({
                id: comicInfo?.comic_id,
                name: comicInfo?.comic_name,
                episode_number: comicInfo?.comic_episode_number,
                page_count: comicInfo.comic_page_count,
                description: comicInfo.comic_description,
                cm_id: comicInfo.comic_cm_id,
                images: comicInfo.images,
                categories: comicInfo.categories,
            })
            setCreators(comicInfo.creators)
            fetchCandyMachine(comicInfo.comic_cm_id)
        }
    }, [comicInfo, fetchCandyMachine])

    const handleSubmit = async () => {
        try {
            if (isDeleting) {
                throw new Error("Deleting in progress.");
            }
            if (isPublishing) {
                throw new Error("Publishing in progress.");
            }
            if (!candyMachine?.itemsAvailable) {
                alert.show('Missing total NFTS', {
                    type: "error",
                })
                throw new Error("Missing total NFTS");
            }
            if (!candyMachine?.goLiveDate) {
                alert.show('Missing goLiveDate', {
                    type: "error",
                })
                throw new Error("Missing goLiveDate");
            }
            if (!candyMachine?.price) {
                alert.show('Missing NFT price', {
                    type: "error",
                })
                throw new Error("Missing NFT price");
            }
            if (candyMachine?.whitelistSettings !== undefined) {
                if (!candyMachine?.whitelistSettings.mint) {
                    alert.show('Missing whitelistSettings mint address.', {
                        type: "error",
                    })
                    throw new Error("Missing whitelistSettings mint address.");
                }
            }

            if (comicInfo === undefined) {
                if (!series?.series_name) {
                    alert.show('Missing series name', {
                        type: "error",
                    })
                    throw new Error("Missing series name.");
                }
                if (!comic?.name) {
                    alert.show('Missing comic name', {
                        type: "error",
                    })
                    throw new Error("Missing comic name.");
                }
                if (!comic?.episode_number) {
                    alert.show('Missing comic episode number', {
                        type: "error",
                    })
                    throw new Error("Missing comic episode number.");
                }
                if (!comic.images || imageFilter(comic.images, "cover").length === 0) {
                    alert.show('Missing comic cover', {
                        type: "error",
                    })
                    throw new Error("Missing comic cover.");
                }
                if (!comic.images || imageFilter(comic.images, "page").length === 0) {
                    alert.show('Missing comic pages', {
                        type: "error",
                    })
                    throw new Error("Missing comic pages.");
                }
                if (creators.length === 0) {
                    alert.show('Missing creators', {
                        type: "error",
                    })
                    throw new Error("Missing creators.");
                }
                if (!candyMachine?.creators) {
                    alert.show('Missing candy machine creators', {
                        type: "error",
                    })
                    throw new Error("Missing candy machine creators");
                }
                let totalCreatorProportions = candyMachine?.creators?.reduce((acc, creator) => (creator.share + acc), 0);
                if (totalCreatorProportions !== 100) {
                    alert.show('Creator proportions must equal 100 in total', {
                        type: "error",
                    })
                    throw new Error("Creator proportions must equal 100 in total");
                }
            }

            setIsPublishing(true);

            let options = {
                method: comicInfo === undefined ? "POST" : "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    publicKey: wallet.publicKey,
                    signature,
                    data: {
                        metadata: comic,
                        series,
                        creators,
                        candyMachine,
                    },
                }),
            };

            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/comic`, options)
                .then(res => res.json())
                .catch(err => {
                    console.log(err);
                })

            console.log(response);

            setIsPublishing(false);
            if (response && response.status === "success") {
                alert.show('Successful update', {
                    type: "success",
                })
                setComic(undefined);
                setCreators([]);
                setSeries(undefined);
                setCandyMachine(undefined);
                setCurrentComicId(-1);
            } else if (response && response.status === "error") {
                alert.show('Something went wrong', {
                    type: "error",
                })
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async () => {
        try {
            setShowDeleteModal(false);
            setIsDeleting(true);

            let options = {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    publicKey: wallet.publicKey,
                    signature,
                    data: {
                        metadata: comic,
                        series,
                        creators,
                        candyMachine,
                    },
                }),
            };

            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/comic`, options)
                .then(res => res.json())
                .catch(err => {
                    console.log(err);
                })

            setIsDeleting(false);

            if (response && response.status === "success") {
                alert.show('Comic deleted', {
                    type: "success",
                })
                setComic(undefined);
                setCreators([]);
                setSeries(undefined);
                setCandyMachine(undefined);
                setCurrentComicId(-1);
            } else if (response && response.status === "error") {
                alert.show('Something went wrong', {
                    type: "error",
                })
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div id="upload-comic-container" className="relative w-full">
            <SeriesForm series={series} setSeries={setSeries} comicInfo={comicInfo} />
            <ComicForm comic={comic} setComic={setComic} series={series} wallet={wallet} signature={signature} comicInfo={comicInfo} />
            <CreatorForm creators={creators} setCreators={setCreators} isNew={!comicInfo} />
            <CandyForm candyMachine={candyMachine} setCandyMachine={setCandyMachine} isNew={!comicInfo} />
            <div id="upload-submit-container" className="w-full h-56 flex justify-center items-center">
                {
                    comicInfo === undefined ?
                        !isPublishing ?
                            <button className="w-56 bg-violet-500 hover:bg-violet-600 text-white p-6 rounded-lg uppercase"
                                onClick={handleSubmit}
                            >
                                Publish
                            </button>
                            :
                            <button className="w-56 bg-violet-500 hover:bg-violet-600 text-white p-6 rounded-lg uppercase flex justify-center">
                                <div className="w-10 h-10 border-l-2 border-white rounded-full animate-spin" />
                            </button>
                        :
                        <div className="w-3/4 flex flex-row justify-around">
                            {
                                !isDeleting ?
                                    <button className="w-56 bg-fuchsia-500 hover:bg-fuchsia-600 text-white p-6 rounded-lg uppercase"
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        Delete
                                    </button>
                                    :
                                    <button className="w-56 bg-fuchsia-500 hover:bg-fuchsia-600 text-white p-6 rounded-lg uppercase flex justify-center">
                                        <div className="w-10 h-10 border-l-2 border-white rounded-full animate-spin" />
                                    </button>
                            }
                            {
                                !isPublishing ?
                                    <button className="w-56 bg-violet-500 hover:bg-violet-600 text-white p-6 rounded-lg uppercase"
                                        onClick={handleSubmit}
                                    >
                                        Edit
                                    </button>
                                    :
                                    <button className="w-56 bg-violet-500 hover:bg-violet-600 text-white p-6 rounded-lg uppercase flex justify-center">
                                        <div className="w-10 h-10 border-l-2 border-white rounded-full animate-spin" />
                                    </button>
                            }
                        </div>
                }
            </div>
            {
                showDeleteModal &&
                <div id="bg-fixed" className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
                    <div className="w-11/12 h-1/2 sm:w-1/2 sm:h-90 bg-white border border-violet-500 rounded-xl z-10 ml-32 flex flex-col justify-center items-center">
                        <h1 className="text-xl mb-10">Are you sure you want to delete this comic?</h1>
                        <div className="w-3/4 flex justify-around items-center">
                            <button className="border border-gray-500 rounded-xl p-4 text-gray-500 hover:bg-gray-500 hover:text-white cursor-pointer"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                No, cancel that.
                            </button>
                            <button className="border border-fuchsia-500 rounded-xl p-4 text-fuchsia-500 hover:bg-fuchsia-500 hover:text-white cursor-pointer"
                                onClick={handleDelete}
                            >
                                Yes, delete it!
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ComicUpload;