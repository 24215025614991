//@ts-ignore
import { binary_to_base58 } from "base58-js"
import { useCallback, useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Wallet } from '@project-serum/anchor';
import Dashboard from "./dashboard/dashboard";

interface AnchorWallet extends Wallet {
    signMessage: any,
}

const Admin = ({ wallet }: { wallet: WalletContextState }) => {
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [signedMessage, setSignedMessage] = useState<string>("");

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        if (wallet.disconnecting) {
            setIsAdmin(undefined);
            setTimeout(() => {
                setIsVerifying(false);
            }, 1000)
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
            signMessage: wallet.signMessage,
        } as AnchorWallet;
    }, [wallet]);

    const handleAdminAccess = useCallback(async (anchorWallet: AnchorWallet) => {
        try {
            if (!anchorWallet) {
                throw new Error("No wallet found");
            }

            let config = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/config`)
                .then(res => res.json())

            const encodedMessage = new TextEncoder().encode(config.message);
            const signatureBinary = await anchorWallet.signMessage(encodedMessage)
            const signedMessage = binary_to_base58(signatureBinary);
            setSignedMessage(signedMessage);

            let walletPubkey: string = anchorWallet.publicKey?.toString()

            const options: RequestInit = {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    signature: signedMessage,
                    publicKey: walletPubkey,
                }),
            }

            let status: number;
            fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/admin`, options)
                .then(res => {
                    status = res.status;
                    return res.json();
                })
                .then(res => {
                    if (status === 200) {
                        if (res && res.publicKey) {
                            if (res.publicKey === walletPubkey) {
                                setIsAdmin(true);
                            }
                        }
                    } else {
                        setIsAdmin(false);
                    }
                })
                .catch(err => { console.log(err) })
        } catch (error) {
            console.log(error);
            wallet.disconnect();
        }
    }, [wallet]);

    useEffect(() => {
        if (anchorWallet) {
            if (!isVerifying) {
                setIsVerifying(true);
                handleAdminAccess(anchorWallet);
            }
        }
    }, [anchorWallet, isVerifying, handleAdminAccess])

    return (
        <div className="relative w-full bg-black overflow-x-hidden flex px-2 md:px-10 py-5">
            <div className="w-full flex flex-col items-center border-2 border-violet-500">
                <div className="w-full min-h-screen-1/2 h-auto flex flex-col justify-start items-center gap-2">
                    {
                        isAdmin !== undefined ?
                            isAdmin ?
                                <Dashboard wallet={wallet} signature={signedMessage} />
                                :
                                <div className="h-full flex flex-col justify-center items-center">
                                    <h1 className="text-violet-400 text-center">
                                        YOU ARE NOT ADMIN
                                    </h1>
                                    <h2 className="text-violet-200 text-center">
                                        Access restricted.
                                    </h2>
                                    <h2 className="text-violet-200 text-center">
                                        Please contact your administrator.
                                    </h2>
                                </div>
                            :
                            <div className="h-full flex flex-col justify-center items-center">
                                <h1 className="text-violet-400 text-center">
                                    CONNECT YOUR WALLET
                                </h1>
                                <h2 className="text-violet-200 text-center">
                                    Admin access only.
                                </h2>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Admin;