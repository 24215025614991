import { Dispatch, SetStateAction, useState } from "react";
import { Creator } from "../../../../../helpers/interfaces";
import { useEffect } from "react";

const CreatorForm = ({ creators, setCreators, isNew }: { creators: Creator[], setCreators: Dispatch<SetStateAction<Creator[]>>, isNew: boolean }) => {
    const [creatorName, setCreatorName] = useState<string>("");
    const [creatorRole, setCreatorRole] = useState<string>("");

    useEffect(() => {
        console.log("TEST");
        console.log(isNew);
    }, [isNew])

    const handleAddCreator = (name: string, role: string) => {
        try {
            if (!name) {
                throw new Error("Missing creator information");
            }
            setCreators(prevstate => ([
                ...prevstate,
                {
                    creator_name: name,
                    creator_role: role || "",
                }
            ]))
            setCreatorName("");
            setCreatorRole("");
        } catch (err) {
            console.log(err);
        }
    };

    const handleRemoveCreator = (name: string, role: string | undefined) => {
        setCreators(prevstate => ([
            ...prevstate.filter(creator => !(creator.creator_name === name && creator.creator_role === role)),
        ]))
    }

    return (
        <div id="creator-container" className="w-full flex flex-col justify-start items-start mb-4">
            <label htmlFor="creators" className="text-white mb-2">Authors <span className="text-fuchsia-500">*</span></label>
            <div className="w-full overflow-x-auto relative rounded-lg h-60 bg-white mb-4">
                <table className="table-auto w-full bg-white text-left">
                    <thead className="bg-violet-300 uppercase sticky top-0">
                        <tr>
                            <th className="w-1/2 py-2 px-4">name</th>
                            <th className="w-1/2 py-2 px-4">role</th>
                            <th className="py-2 px-2">action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="even:bg-violet-100">
                            <td className="py-2 px-4">
                                <input
                                    type="text"
                                    className="w-full border-violet-200 border-2 rounded-lg px-2"
                                    value={creatorName}
                                    onChange={(e) => setCreatorName(e.target.value)}
                                    disabled={!isNew}
                                />
                            </td>
                            <td className="py-2 px-4">
                                <input
                                    type="text"
                                    className="w-full border-violet-200 border-2 rounded-lg px-2"
                                    value={creatorRole}
                                    onChange={(e) => setCreatorRole(e.target.value)}
                                    disabled={!isNew}
                                />
                            </td>
                            <td className="py-2 px-4 text-center">
                                <button className={`py-1 px-2 rounded-lg ${isNew ? "bg-violet-500 hover:bg-violet-600 pointer" : "bg-gray-500"}`}
                                    onClick={() => isNew && handleAddCreator(creatorName, creatorRole)}
                                >
                                    +
                                </button>
                            </td>
                        </tr>
                        {
                            creators.length > 0 &&
                            creators.map(creator => (
                                <tr key={`${creator.creator_name}-${creator.creator_role}`} className="even:bg-violet-100">
                                    <td className="py-2 px-4">
                                        {creator.creator_name}
                                    </td>
                                    <td className="py-2 px-4">
                                        {creator.creator_role}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        <button className={`px-2 rounded-lg ${isNew ? "bg-fuchsia-500 hover:bg-fuchsia-600 pointer" : "bg-gray-500"}`}
                                            onClick={() => isNew && handleRemoveCreator(creator.creator_name, creator.creator_role)}
                                        >
                                            X
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CreatorForm;