import * as anchor from '@project-serum/anchor';
import { WalletContextState } from "@solana/wallet-adapter-react";
import MintCard from '../../components/mints/mint-card/mint-card';

const Hoverboards = ({ wallet }: { wallet: WalletContextState }) => {
    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
    const connection = new anchor.web3.Connection(
        rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
    );

    const artworks = [
        { name: "SYD-RYDZ", image: "/SYD_RYDZ_Hoverboards.png", cm_id: process.env.REACT_APP_SYD_RYDZ_CM_ID, token: "SOL", coming_soon: 0 },
    ];

    return (
        <div className="relative w-full min-h-screen bg-black overflow-x-hidden">
            <div className="w-full flex justify-center items-center px-8 sm:px-16 sm:py-6">
                <div className="w-full h-auto bg-gradient-to-b from-violet-500 via-pink-500 to-orange-300 p-1">
                    <img className="w-full h-auto" src="/SYD_RYDZ_Banner.png" alt="booster promo" />
                </div>
            </div>
            <div className="w-full h-full text-white p-8 flex flex-row flex-wrap justify-center items-center gap-32">
                {
                    artworks.map(artwork => (
                        <MintCard key={artwork.cm_id} mintInfo={artwork} connection={connection} wallet={wallet} showEdition={false} />
                    ))
                }
            </div>
        </div>
    )
}

export default Hoverboards;