import { useCallback, useEffect, useState } from "react";
import BackButton from "./components/back-button";
import { Collectible, Comic } from "../../../helpers/interfaces";
import CollectibleList from "./pages/collectibles/collectible-list/collectible-list";
import CollectibleUpload from "./pages/collectibles/collectible-upload/collectible-upload";
import ComicList from "./pages/comics/comic-list/comic-list";
import ComicUpload from "./pages/comics/comic-upload/comic-upload";
import { WalletContextState } from "@solana/wallet-adapter-react";

const Dashboard = ({ wallet, signature }: { wallet: WalletContextState, signature: string }) => {
    /*
    currentComicId === -1 => dashboard
    currentComicId === 0 => new comic
    currentComicId > 0 => edit comic
    */
    const [currentPage, setCurrentPage] = useState<string>("collectibles");

    const [currentCollectibleId, setCurrentCollectibleId] = useState<number>(-1);
    const [currentComicId, setCurrentComicId] = useState<number>(-1);

    const [comicList, setComicList] = useState<Comic[]>([]);
    const [areComicsFetched, setAreComicsFetched] = useState<boolean>(false);

    const [collectibleList, setCollectibleList] = useState<Collectible[]>([]);
    const [areCollectiblesFetched, setAreCollectiblesFetched] = useState<boolean>(false);

    const fetchComics = useCallback(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/comics/inventory`)
            .then(res => (res.json()))
            .then(res => {
                if (res && res.data) {
                    setComicList(res.data);
                    //setComicPreview(res.data[0]);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const fetchCollectibles = useCallback(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/collectibles`)
            .then(res => (res.json()))
            .then(res => {
                if (res && res.data) {
                    console.log(res.data);
                    setCollectibleList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        setAreComicsFetched(false);
    }, [currentComicId])

    useEffect(() => {
        setAreCollectiblesFetched(false);
    }, [currentCollectibleId])

    useEffect(() => {
        if (currentPage === "comics") {
            if (!areComicsFetched) {
                fetchComics();
                setAreComicsFetched(true);
            }
        }
    }, [currentPage, areComicsFetched, fetchComics])


    useEffect(() => {
        if (currentPage === "collectibles") {
            if (!areCollectiblesFetched) {
                fetchCollectibles();
                setAreCollectiblesFetched(true);
            }
        }
    }, [currentPage, areCollectiblesFetched, fetchCollectibles])

    return (
        <div className="w-full h-full flex">
            <div className="hidden sm:flex flex-col w-40 h-full bg-violet-500 items-center p-6 gap-4">
                <div className="flex flex-col items-center">
                    <button
                        className={`w-20 h-20 bg-violet-600 hover:bg-violet-700 shadow-lg cursor-pointer rounded-xl mb-1 flex justify-center items-center ${currentPage === "comics" && "shadow-white"}`}
                        onClick={() => { setCurrentPage("comics"); setCurrentComicId(-1) }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-10 h-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                    </button>
                    <p className="cursor-default text-white text-center">Upload comics</p>
                </div>
                <div className="flex flex-col items-center">
                    <button
                        className={`w-20 h-20 bg-violet-600 hover:bg-violet-700 shadow-lg cursor-pointer rounded-xl mb-1 flex justify-center items-center ${currentPage === "collectibles" && "shadow-white"}`}
                        onClick={() => { setCurrentPage("collectibles"); setCurrentComicId(-1); }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-10 h-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                        </svg>
                    </button>
                    <p className="cursor-default text-white text-center">Upload collectibles</p>
                </div>
            </div>
            <div className="flex-grow h-full p-6">
                {
                    currentPage === "comics" ?
                        currentComicId < 0 ?
                            <ComicList comicList={comicList} setCurrentComicId={setCurrentComicId} />
                            :
                            currentComicId === 0 ?
                                <>
                                    <BackButton setCurrentId={setCurrentComicId} />
                                    <ComicUpload wallet={wallet} signature={signature} comicInfo={undefined} setCurrentComicId={setCurrentComicId} />
                                </>
                                :
                                comicList?.length > 0 ?
                                    <>
                                        <BackButton setCurrentId={setCurrentComicId} />
                                        <ComicUpload wallet={wallet} signature={signature} comicInfo={comicList.filter((comic: Comic) => comic.comic_id === currentComicId)[0]} setCurrentComicId={setCurrentComicId} />
                                    </>
                                    :
                                    <>
                                        <BackButton setCurrentId={setCurrentComicId} />
                                        <div className="w-full h-128 flex justify-center items-center">
                                            <img className="w-60 h-auto" src="/loading.gif" alt="spinner" />
                                        </div>
                                    </>
                        :
                        <></>
                }
                {
                    currentPage === "collectibles" ?
                        currentCollectibleId < 0 ?
                            <CollectibleList collectibleList={collectibleList} setCurrentCollectibleId={setCurrentCollectibleId} />
                            :
                            currentCollectibleId === 0 ?
                                <>
                                    <BackButton setCurrentId={setCurrentCollectibleId} />
                                    <CollectibleUpload wallet={wallet} signature={signature} collectibleInfo={undefined} setCurrentCollectibleId={setCurrentCollectibleId} />
                                </>
                                :
                                collectibleList?.length > 0 ?
                                    <>
                                        <BackButton setCurrentId={setCurrentCollectibleId} />
                                        <CollectibleUpload wallet={wallet} signature={signature} collectibleInfo={collectibleList.filter((collectible: Collectible) => collectible.collectible_id === currentCollectibleId)[0]} setCurrentCollectibleId={setCurrentCollectibleId} />
                                    </>
                                    :
                                    <>
                                        <BackButton setCurrentId={setCurrentCollectibleId} />
                                        <div className="w-full h-128 flex justify-center items-center">
                                            <img className="w-60 h-auto" src="/loading.gif" alt="spinner" />
                                        </div>
                                    </>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default Dashboard;