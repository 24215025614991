import { Comic } from "../../../../../../helpers/interfaces";
import UploadComicBlock from "./components/upload-comic-block";

const ComicList = ({ comicList, setCurrentComicId }: { comicList: Comic[], setCurrentComicId: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <div className="w-full h-full">
            <div className="w-full flex items-center justify-between">
                <h1 className="text-violet-500 text-xl">COMIC LIST</h1>
                <button
                    className="bg-violet-500 rounded p-2 px-4 hover:bg-violet-600 text-white"
                    onClick={() => { setCurrentComicId(0) }}
                >
                    New comic +
                </button>
            </div>
            <div className="w-full min-h-screen-1/2 my-4 border border-violet-500">
                <div className="w-full h-10 bg-violet-500"></div>
                <div className="w-full h-full p-4">
                    {
                        comicList?.length > 0 ?
                            comicList.map((comic: Comic, index: number) => (
                                <UploadComicBlock key={comic.comic_id} index={index} comicInfo={comic} setCurrentComicId={setCurrentComicId} />
                            ))
                            :
                            <div className="w-full h-128 flex justify-center items-center">
                                <img className="w-60 h-auto" src="/loading.gif" alt="spinner" />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ComicList;