const BackButton = ({ setCurrentId }: { setCurrentId: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <button
            className="flex justify-center items-center text-violet-500 mb-4"
            onClick={() => { setCurrentId(-1) }}                                >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
            <p className="text-xl">
                BACK
            </p>
        </button>
    )
}

export default BackButton;