import MintCard from '../../components/mints/mint-card/mint-card';
import { MintInterface } from "../../helpers/interfaces";
import { useEffect, useState } from 'react';
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

interface CollectibleInterface {
    collectible_coming_soon: number,
    collectible_cm_id: string | undefined,
    collectible_id: string,
    collectible_image: string,
    collectible_name: string,
    collectible_token: string,
}

const Collectibles = ({ wallet }: { wallet: WalletContextState }) => {
    const [artworkList, setArtworkList] = useState<MintInterface[]>([]);
    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
    const connection = new anchor.web3.Connection(
        rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
    );

    let areArtworksFetched = false;

    const fetchArtworks = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/collectibles`)
            .then(res => (res.json()))
            .then(res => {
                console.log(res);
                if (res && res.data) {
                    let collectibleArray = res.data.map((data: CollectibleInterface) => ({
                        cm_id: data.collectible_cm_id,
                        id: data.collectible_id,
                        image: data.collectible_image,
                        name: data.collectible_name,
                        token: data.collectible_token,
                        coming_soon: data.collectible_coming_soon,
                    }))
                    setArtworkList(collectibleArray);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (!areArtworksFetched) {
            fetchArtworks();
            // eslint-disable-next-line
            areArtworksFetched = true;
        }
    }, [])

    return (
        <div className="relative w-full min-h-screen bg-black overflow-x-hidden">
            <div className="w-full flex justify-center items-center px-8 sm:px-16 sm:py-6">
                <div className="w-full h-auto p-1 text-center">
                    <h1 className="uppercase font-extrabold text-transparent font-archivo text-4xl bg-clip-text bg-gradient-to-b from-violet-100 to-violet-500 lg:whitespace-nowrap mt-5 mb-10">Collectibles</h1>
                </div>
            </div>
            <div className="w-full h-full text-white p-8 flex flex-row flex-wrap justify-center items-center gap-32">
                {
                    artworkList.map(artwork => (
                        <MintCard key={artwork.cm_id} mintInfo={artwork} connection={connection} wallet={wallet} showEdition={true} />
                    ))
                }
            </div>
        </div>
    )
}

export default Collectibles;