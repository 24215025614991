import ConnectButton from "../../buttons/connect-button/connect-button";
import { Connection } from "@solana/web3.js";
import { formatNumber } from "../../buttons/mint-button/utils";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine"
import MintButton from "../../buttons/mint-button/mint-button";
import { MintInterface } from "../../../helpers/interfaces";
import { programs } from "@metaplex/js";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

const MintCard = ({ mintInfo, connection, wallet, showEdition }: { mintInfo: MintInterface, connection: Connection, wallet: WalletContextState, showEdition: boolean }) => {
    const [candyMachine, setCandyMachine] = useState<any>();
    const [splTokenName, setSplTokenName] = useState<string>("");

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet && mintInfo.cm_id) {
                const candyMachineId = new anchor.web3.PublicKey(
                    mintInfo.cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);
                if (cndy.state.tokenMint !== null) {
                    try {
                        if (cndy.state.tokenMint.toString() === "CtEYJ44W7PctR1Bc7tHJxfEX4vvzc8aAxZYzGwGWscT9") {
                            setSplTokenName("EVO");
                        } else {
                            let mintInfo = await programs.metadata.Metadata.findByMint(connection, cndy.state.tokenMint);
                            let tokenName: string = mintInfo?.data?.data?.name || "";
                            setSplTokenName(tokenName);
                        }
                    } catch (err) {
                        console.log("Token info not found.")
                        setSplTokenName("Unkown");
                    }
                }
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, mintInfo, connection])

    if (mintInfo.cm_id) {
        return (
            <div className={`w-full sm:w-80 flex flex-col p-4 justify-center items-center border-2 my-4 ${!splTokenName ? "border-violet-500" : "border-orange-400"}`}>
                <h1 className="uppercase font-extrabold text-transparent font-archivo text-xl bg-clip-text bg-gradient-to-b from-violet-100 to-violet-500 lg:whitespace-nowrap">{mintInfo.name}</h1>
                <h2 className="h-4 mb-4 uppercase">{mintInfo.coming_soon ? "Coming soon" : ""}</h2>

                <div className="w-full flex flex-col justify-center items-center">
                    <img className="w-full h-96 object-cover border-2 border-gray-500 p-2 rounded" src={mintInfo.image} alt={mintInfo.name} />
                    <div className="flex flex-row whitespace-nowrap justify-between my-2 mt-4">
                        {
                            candyMachine && candyMachine?.state &&
                            <>
                                {
                                    showEdition &&
                                    <>
                                        <p>
                                            {`Remaining: ${candyMachine.state.itemsRemaining}`}
                                        </p>
                                        <p className="mx-2">|</p>
                                        <p>
                                            {`Edition: ${candyMachine.state.itemsAvailable}`}
                                        </p>
                                        <p className="mx-2">|</p>
                                    </>
                                }
                                <p>
                                    {`${formatNumber.asNumber(candyMachine.state.price)} ${splTokenName || "SOL"}`}
                                </p>
                            </>
                        }
                    </div>
                    <div className="w-24 m-2">
                        {
                            !wallet || !wallet.connected ?
                                <ConnectButton color={!splTokenName ? "#8B5CF6" : "#fb923c"} />
                                :
                                <MintButton candyMachine={candyMachine} color={!splTokenName ? "violet-500" : "orange-400"} isPromo={false} wallet={wallet} />
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default MintCard;
