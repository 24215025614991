import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="w-full h-40 bg-black text-white flex justify-center items-center p-6">
            <Link to={"/"}>
                <img className="w-52 h-auto" src="/RD_logo_1600.png" alt="RainyDayz" />
            </Link>
        </div>
    )
}

export default Footer;