import { Connection } from "@solana/web3.js";
import { Character, Comic } from "../../../helpers/interfaces";
import MintCharacter from "../../mints/mint-character/mint-character";
import ReactCarousel from "react-multi-carousel";
import { WalletContextState } from "@solana/wallet-adapter-react";

import "react-multi-carousel/lib/styles.css";
import "./character-swiper.scss";

const responsive = {
    largeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 800 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 800, min: 0 },
        items: 1,
    },
};

const CharacterSwiper = ({ comic, connection, characterList, wallet }: { comic: Comic, connection: Connection, characterList: Character[], wallet: WalletContextState }) => {
    return (
        <div id="character-swiper-container" className="w-full h-full mb-2">
            <ReactCarousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true}
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                focusOnSelect={false}
                customTransition="transform 300ms ease-in-out"
                transitionDuration={300}
            >
                {
                    characterList?.length > 0 &&
                    characterList.map((character, index) => (
                        <MintCharacter key={index} comic={comic} connection={connection} character={character} index={index} wallet={wallet}/>
                    ))
                }
            </ReactCarousel>
        </div>
    );
}

export default CharacterSwiper;